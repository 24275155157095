import React from "react";

import TalentSearchBar from "Components/TalentSearchBar";
import ProfileCard, { items } from "Components/ProfileCard";
import prof1 from "Assets/images/Ellipse 17.png";
import arrowLeft from "Assets/images/projectcard/arrow.png";
import arrowRight from "Assets/images/projectcard/arrow1.png";
import styles from "./style.module.scss";

const profileArray: items[] = [
  {
    image: prof1,
    title: "Python Developer",
    email: "jody.booms@gmail.com",
    name: "Jody Booms",
    location: "Madagascar",
    rating: "3.5",
    badge: true,
  },
  {
    image: prof1,
    title: "Python Developer",
    email: "jody.booms@gmail.com",
    name: "Jody Booms",
    location: "Madagascar",
    rating: "3.5",
    badge: true,
  },
  {
    image: prof1,
    title: "Python Developer",
    email: "jody.booms@gmail.com",
    name: "Jody Booms",
    location: "Madagascar",
    rating: "3.5",
  },
  {
    image: prof1,
    title: "Python Developer",
    email: "jody.booms@gmail.com",
    name: "Jody Booms",
    location: "Madagascar",
    rating: "3.5",
    favorite: true,
  },
  {
    image: prof1,
    title: "Python Developer",
    email: "jody.booms@gmail.com",
    name: "Jody Booms",
    location: "Madagascar",
    rating: "3.5",
  },
  {
    image: prof1,
    title: "Python Developer",
    email: "jody.booms@gmail.com",
    name: "Jody Booms",
    location: "Madagascar",
    rating: "3.5",
  },
  {
    image: prof1,
    title: "Python Developer",
    email: "jody.booms@gmail.com",
    name: "Jody Booms",
    location: "Madagascar",
    rating: "3.5",
    favorite: true,
  },
  {
    image: prof1,
    title: "Python Developer",
    email: "jody.booms@gmail.com",
    name: "Jody Booms",
    location: "Madagascar",
    rating: "3.5",
  },
];

const FreelancerSearch = () => {
  return (
    <div>
      <main className={styles.mainSection}>
        <div className={styles.top}>
          <p>Type a skill</p>
          <TalentSearchBar />
        </div>
        <div className={styles.cardContainer}>
          <p>Found 200 freelancers that are within the skill range</p>
          <div className={styles.freelancers}>
            {profileArray.map((profiles, index) => (
              <ProfileCard {...profiles} key={index} />
            ))}
          </div>
          <div className={styles.paginate}>
            <div className={styles.numbers}>
              <p>1</p>
              <p>2</p>
              <p>3</p>
              <p>4</p>
              <p>5</p>
              <p>...</p>
            </div>
            <div className={styles.navarrow}>
              <div className={styles.arrow}>
                <img src={arrowLeft} alt="arrows" />
              </div>
              <div className={styles.arrow}>
                <img src={arrowRight} alt="arrows" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FreelancerSearch;
