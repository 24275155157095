import React, {
  useState,
  useEffect,
  useRef,
  ReactType,
  useCallback,
} from "react";
import { Switch, Route } from "react-router-dom";
import SignIn from "./SignIn";
import Signup from "./Signup";
import ForgotPassword from "./forgotPassword";
import ChangePassword from "./changePassword";
import HirerSignup from "./HirerSignup";
// import HirerSignup1 from "./DeprecaredHirerSignup1";
// import HirerSignup2 from "./DeprecatedHirerSignup2";
import Activation from "./Activation";
import styles from "./style.module.scss";
import auth1Img from "Assets/images/auth1.svg";
import auth2Img from "Assets/images/auth2.svg";
import leftarrCircle from "Assets/images/leftarrCircle.svg";
import rightarrCircle from "Assets/images/rightarrCircle.svg";

const routes = [
  {
    path: "signin",
    exact: true,
    Child: SignIn,
  },
  {
    path: "signup",
    exact: true,
    Child: Signup,
  },
  {
    path: "forgot-password",
    exact: true,
    Child: ForgotPassword,
  },
  {
    path: "change-password",
    exact: true,
    Child: ChangePassword,
  },
  {
    path: "hire-talent",
    exact: true,
    Child: HirerSignup,
  },
  // {
  //   path: "hire-talent-step-1",
  //   exact: true,
  //   Child: HirerSignup1,
  // },
  // {
  //   path: "hire-talent-step-2",
  //   exact: true,
  //   Child: HirerSignup2,
  // },
  {
    path: "activation",
    exact: true,
    Child: Activation,
  },
];
interface Props {}

const imgs = [auth1Img, auth2Img];

const AuthRoutes = (props: Props) => {
  const [step, setStep] = useState(0);
  const [st, setSte] = useState(1);
  const [images, setImg] = useState(imgs);
  let sliderRef = useRef<HTMLDivElement>(null);
  // let { path: authPath } = useRouteMatch();
  const stepper = useCallback(() => {
    if (step === 0) {
      setStep(1);

      if (st >= 20) {
        setSte(1);
        setImg([images[0], images[1]]);
      } else {
        setImg([...images, images[images.length - 2]]);
        setSte((prev) => prev + 1);
      }

      return (
        sliderRef.current &&
        sliderRef.current.scrollTo({
          top: 0,
          left: sliderRef.current.clientWidth * st,
          behavior: "smooth",
        })
      );
    }
    if (step === 1) {
      setStep(0);
      if (st >= 20) {
        setSte(1);
        setImg([images[0], images[1]]);
      } else {
        setImg([...images, images[images.length - 2]]);
        setSte((prev) => prev + 1);
      }
      return (
        sliderRef.current &&
        sliderRef.current.scrollTo({
          top: 0,
          left: sliderRef.current.clientWidth * st,
          behavior: "smooth",
        })
      );
    }
  }, [step, st, images]);

  useEffect(() => {
    let interval = setInterval(() => stepper(), 5000);
    return () => {
      clearInterval(interval);
    };
  }, [step, stepper]);
  // console.log(step, st, images);
  return (
    <div className={styles.authRoutes}>
      <div className={styles.left}>
        <Switch>
          {routes.map(
            (
              {
                path,
                exact,
                Child,
              }: {
                path: string;
                exact: boolean;
                Child: ReactType;
              },
              index
            ) => (
              <Route exact={exact} path={`/auth/${path}`} key={index}>
                <>
                  <Child />
                </>
              </Route>
            )
          )}
        </Switch>
      </div>
      <div className={`${styles.right} ${step === 0 ? styles.second : ""}`}>
        <div className={styles.carousel} ref={sliderRef}>
          {images.map((img, i) => (
            <div key={i} className={styles.item}>
              <img src={img} alt="slider" />
            </div>
          ))}
        </div>
        <div className={styles.top}>
          <p>
            Find the right talent for your business, Startup, Project or Product
            idea.
          </p>
          <span>
            <button onClick={stepper}>
              <img src={leftarrCircle} alt="left button" />
            </button>
            <button onClick={stepper}>
              <img src={rightarrCircle} alt="right button" />
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default AuthRoutes;
