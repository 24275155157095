import React from "react";
import style from "./talent.module.scss";
import verify from "Assets/svg/verify.svg";
import flag from "Assets/images/flag.svg";

export interface TalentItem {
  image: string;
  name: string;
  badge?: boolean;
  action?: () => void;
  styles?: Object;
  title: string;
  country: string;
  straight?: boolean;
}

const TalentCard = ({
  image,
  title,
  badge,
  action,
  styles,
  straight,
  name,
  country,
}: TalentItem) => {
  return (
    <div className={style.card} onClick={action} style={styles}>
      <img
        className={`${straight ? style.straight : ""}`}
        src={image}
        alt="talent"
      />
      <p className={style.text}>{name}</p>
      <div className={style.bottom}>
        <div className={style.side}>
          <div>
            <p className={style.name}>{title}</p>
            <p className={style.location}>
              <img className={style.locate} src={flag} alt="badge" />
              {country}
            </p>
          </div>
        </div>
        {badge ? (
          <p className={style.due}>
            <img src={verify} alt="badge" />
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default TalentCard;
