import React from "react";
import style from "./style.module.scss";
import del from "Assets/images/delete.svg";
import videoSchedule from "Assets/images/vidsch.svg";

export interface notifItem {
  avatar?: string;
  sender?: string;
  time: string;
  message: string;
  prodUpdate?: boolean;
  prodFeedback?: boolean;
  wrkspacePay?: boolean;
  walletBal?: boolean;
  vidCallSchedule?: boolean;
}

const Notifs = ({
  avatar,
  sender,
  time,
  message,
  prodUpdate,
  prodFeedback,
  wrkspacePay,
  walletBal,
  vidCallSchedule,
}: notifItem) => {
  // if (prodUpdate) {
  //   (avatar = prodUpd), (name = "Product Updates");
  // }
  // const setUpdate = () => avatar: prodUpd name = "Product Updates"

  return (
    <div className={style.notifs}>
      {vidCallSchedule ? (
        <>
          <div className={style.avatar}>
            <img src={videoSchedule} alt="type of notif" />
          </div>
          <div className={style.details}>
            <div className={style.diff}>
              <p className={style.name}>Video call schedule</p>
              <p className={style.time}>
                <span className={style.dot}></span>
                {time}
              </p>
            </div>
            <p className={style.message}>{message}</p>
          </div>
        </>
      ) : (
        <>
          <div className={style.avatar}>
            <img src={avatar} alt="type of notif" />
          </div>{" "}
          <div className={style.details}>
            <div className={style.diff}>
              <p className={style.name}>{sender}</p>
              <p className={style.time}>
                <span className={style.dot}></span>
                {time}
              </p>
            </div>
            <p className={style.message}>{message}</p>
          </div>
        </>
      )}
      <div className={style.more}>
        <img src={del} alt="details" />
      </div>
    </div>
  );
};

export default Notifs;
