import React from "react";

import ProjectSearchBar from "Components/ProjectSearchBar";
import Checkbox from "Components/Checkbox";
import ProjectCard, { CardItem } from "Components/ProjectCard";
import arrowLeft from "Assets/images/projectcard/arrow.png";
import arrowRight from "Assets/images/projectcard/arrow1.png";
import img1 from "Assets/images/projectcard/Rectangle 9 (4).png";
import authorPic from "Assets/images/Ellipse 17.png";
import styles from "./style.module.scss";

const specStyle = {
  minWidth: "100%",
  paddingBottom: "40px",
};

const projectArray: CardItem[] = [
  {
    image: img1,
    title: "The new musical app in the industry",
    author: authorPic,
    name: "Adeosun Babra",
    location: "Brazil",
    styles: specStyle,
    row: true,
  },
  {
    image: img1,
    title: "The new musical app in the industry",
    author: authorPic,
    name: "Adeosun Babra",
    location: "Brazil",
    styles: specStyle,
    row: true,
  },
  {
    image: img1,
    title: "The new musical app in the industry",
    author: authorPic,
    name: "Adeosun Babra",
    location: "Brazil",
    styles: specStyle,
    row: true,
  },
  {
    image: img1,
    title: "The new musical app in the industry",
    author: authorPic,
    name: "Adeosun Babra",
    location: "Brazil",
    styles: specStyle,
    row: true,
  },
  {
    image: img1,
    title: "The new musical app in the industry",
    author: authorPic,
    name: "Adeosun Babra",
    location: "Brazil",
    styles: specStyle,
    row: true,
  },
  {
    image: img1,
    title: "The new musical app in the industry",
    author: authorPic,
    name: "Adeosun Babra",
    location: "Brazil",
    styles: specStyle,
    row: true,
  },
];

const ProjectSearch = () => {
  return (
    <div>
      <main className={styles.mainSection}>
        <div className={styles.top}>
          <ProjectSearchBar />
          <div className={styles.box}>
            <Checkbox
              label= "Show projects in my timezone"
            />
          </div>
        </div>
        <div className={styles.cardContainer}>
          <div className={styles.projects}>
            {projectArray.map((projects, index) => (
              <ProjectCard {...projects} key={index} />
            ))}
          </div>
          <div className={styles.paginate}>
            <div className={styles.numbers}>
              <p>1</p>
              <p>2</p>
              <p>3</p>
              <p>4</p>
              <p>5</p>
              <p>...</p>
            </div>
            <div className={styles.navarrow}>
              <div className={styles.arrow}>
                <img src={arrowLeft} alt="arrows" />
              </div>
              <div className={styles.arrow}>
                <img src={arrowRight} alt="arrows" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ProjectSearch;
