import React, { useEffect } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./oneworkspace.module.scss";
import pro1 from "Assets/images/projectcard/Rectangle 9 (4).png";
import loc from "Assets/images/projectcard/Vector.png";
import price from "Assets/images/suitcase.png";
import time from "Assets/images/time.svg";
import love from "Assets/images/love.svg";
import tools from "Assets/images/tools.svg";
import space from "Assets/images/spaces.svg";
import disclaim from "Assets/images/disclaim.svg";
import Checkbox from "Components/Checkbox";
import Button from "Components/Button";
import message from "Assets/images/message.svg";
import banner from "Assets/images/bann2.png";
import calendar from "Assets/images/calendar.svg";
import { getProjectDetails } from "Store/actions/project.action";
import { Project } from "./interfaces";
import { calculateDueDate } from "Utils/helpers";

interface Props {}

const OneWorkspace = (props: Props) => {
  const history = useHistory();
  // const dispatch = useDispatch();
  const route = useRouteMatch();

  // const { id } = useParams();

  const { loading, project, user, networkOnline } = useSelector(
    ({ projectReducer, userReducer, networkReducer }: any) => ({
      loading: projectReducer.loading,
      project: projectReducer.singleProject as Project,
      user: userReducer.user,
      networkOnline: networkReducer.networkOnline,
    })
  );

  // useEffect(() => {
  //   if (networkOnline) {
  //     dispatch(getProjectDetails(id, user.accounts));
  //   }
  // }, [networkOnline, dispatch]);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // const image =
  //   project.images && project.images.length ? project.images[0] : banner;

  return (
    <main className={styles.oneworkspace}>
        <section className={styles.details}>
          <div className={styles.left}>
            <img className={styles.wksp} src={pro1} alt="Workspace image" />
            <div className={styles.disclaimer}>
              <p className={styles.disclaim}>
                <img src={disclaim} alt="comment" />
                Disclaimer
              </p>
              <p className={styles.note}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
          <div className={styles.right}>
            <h2 className={styles.title}>API Project Workspace</h2>
            <p className={styles.location}>
              <img src={loc} alt="location" />
              Sweden, Stockholm
            </p>
            <p className={styles.body}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <div className={styles.under}>
              <p className={styles.feat}>Features</p>
              <div className={styles.more}>
                <p className={styles.item}>
                  <img src={space} alt="spaces" />
                  Spaces
                </p>
                <p className={styles.item}>
                  <img src={love} alt="convenient" />
                  Convenience
                </p>
                <p className={styles.item}>
                  <img src={tools} alt="tools" />
                  Tools
                </p>
              </div>
            </div>
            <div className={styles.info}>
              <div className={styles.tag}>
                <p className={styles.hours}>
                  Working hours
                  <img src={time} alt="Working hours" />
                </p>
                <div className={styles.mid}>
                  <div className={styles.days}>
                    <p className={styles.day}>Monday - Thursday</p>
                    <p className={styles.time}>6:00AM - 10:00PM</p>
                  </div>
                  <div className={styles.days}>
                    <p className={styles.day}>Friday - Saturday</p>
                    <p className={styles.time}>6:00AM - 8:00PM</p>
                  </div>
                  <div className={styles.days}>
                    <p className={styles.day}>Sunday</p>
                    <p className={styles.time}>6:00AM - 7:00PM</p>
                  </div>
                </div>
              </div>
              <div className={styles.action}>
                <p className={styles.cadence}>Pricing (per month)</p>
                <div className={styles.nnow}>
                  <p className={styles.item}>
                    <img src={price} alt="tools" />
                    $11,500
                  </p>
                <Button
                  text="Subscribe"
                  small
                  action={() => null}
                  styles={{
                    padding: "12px 42px",
                    fontSize: "13px",
                    marginTop: "15px"
                  }}
                />
                </div>
              </div>
            </div>
          </div>
        </section>
    </main>
  );
};

export default OneWorkspace;
