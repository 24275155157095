import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import styles from "./style.module.scss";
import logo from "Assets/images/logo.svg";
import Button from "Components/Button";
import Input from "Components/Input";
import FormGrid from "Components/FormGrid";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "Store/actions/user.action";

interface SignUpState {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export default function Signup() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState<SignUpState>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });
  const { loading } = useSelector(({ userReducer }: any) => ({
    loading: userReducer.loading,
  }));
  const signUpAction = (e?: Event) => {
    e && e.preventDefault();
    dispatch(
      registerUser({ ...state, isTalent: true }, () =>
        history.push("/auth/activation")
      )
    );
  };

  const setValue = (e: any) =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  return (
    <div className={styles.Signup}>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="logo" />
      </Link>

      <h3 className={styles.welcome}>Join Workova, earn stable income</h3>

      <form autoComplete="off" className={styles.form}>
        <FormGrid>
          <Input
            value={state.firstName}
            name="firstName"
            action={setValue}
            placeholder="Your first name"
            label="First name"
          />
          <Input
            value={state.lastName}
            name="lastName"
            action={setValue}
            placeholder="Enter last name"
            label="Last name"
          />
        </FormGrid>
        <Input
          value={state.email}
          name="email"
          action={setValue}
          placeholder="Your email address"
          label="Email address"
          margin
        />
        <Input
          value={state.password}
          name="password"
          action={setValue}
          placeholder="Enter password"
          label="Choose a password"
          password
          margin
        />

        <Button
          full
          styles={{
            marginTop: "20px",
            marginBottom: "5%",
          }}
          loading={loading}
          disabled={
            (!state.email &&
              !state.password &&
              !state.firstName &&
              !state.lastName) ||
            loading
          }
          text="Join Workova"
          action={signUpAction}
        />
      </form>
      <p className={styles.footer}>
        <Link to="/auth/signin">Have an account? Sign In</Link>
      </p>
    </div>
  );
}
