import {
  USER_SIGN_IN_REQUEST,
  USER_REGISTER_FAILURE,
  USER_SIGN_IN_SUCCESS,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_SIGN_IN_FAILURE,
} from "../constants/user.contants";

interface UserProps {
  user: Object;
  tempUser: Object;
  loading: boolean;
  error: Object | null;
}
const initialState: UserProps = {
  user: {},
  tempUser: {},
  loading: false,
  error: null,
};

export default function userReducer(state = initialState, action: any): any {
  switch (action.type) {
    case USER_SIGN_IN_REQUEST:
      return { ...state, loading: true };
    case USER_SIGN_IN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case USER_SIGN_IN_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case USER_REGISTER_REQUEST:
      return { ...state, loading: true };
    case USER_REGISTER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case USER_REGISTER_SUCCESS:
      return { ...state, loading: false, tempUser: action.payload };

    default:
      return state;
  }
}
