import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import { useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
import styles from "./style.module.scss";
import logo from "Assets/images/logo.svg";
import { Wizard, WizardStep } from "./Wizard";
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "Store/actions/user.action";

const sleep = (ms: any) => new Promise(resolve => setTimeout(resolve, ms));


export interface FormProps {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyName: string;
  projectType: string;
  specification: string;
  budget: string;
  country: string;
  skills: any[];
}

const pageOneValidationSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!')
    .required('First name is required'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!')
    .required('Last name is required'),
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().min(6, 'Password should be at least 6 characters')
    .required('Password is required'),
  companyName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!')
    .required('Company name is required'),
  projectType: Yup.string()
    .required('Please select a project type'),
  specification: Yup.string()
    .required('Select a specification'),
  budget: Yup.number()
    .required('Budget is required'),
});

const pageTwoValidationSchema = Yup.object().shape({
  country: Yup.string().required(),
  skills: Yup.array().of(Yup.string())
    .min(1, 'Select at least one skill')
    .required('Select at least one skill').nullable(),
});

export const initialValues = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  companyName: "",
  budget: "",
  projectType: "",
  specification: "",
  country: "",
  skills: [],
}

const HirerSignup = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { loading } = useSelector(({ userReducer }: any) => ({
    loading: userReducer.loading,
  }));

  const signUpAction = (formValues: FormikValues) => {
    dispatch(
      // @ts-ignore
      // TODO: clean up input from "formValues" to match the input required by "registerUser"
      // TODO: or just implement an action for registering recruiter
      registerUser({ ...formValues, isRecruiter: true }, () =>
        history.push("/auth/activation")
      )
    );
  };

  return (
    <div className={styles.HireSignup}>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="logo" />
      </Link>

      <h3 className={styles.welcome}>
        Hire a talent, get your projects delivered
      </h3>
      <Wizard
        initialValues={initialValues}
        onSubmit={async (values: any) => {
          sleep(300).then(() => console.log("Wizard submit", values));
          signUpAction(values);
        }}
      >
        <WizardStep validationSchema={pageOneValidationSchema}>
          <PageOne />
        </WizardStep>
        <WizardStep validationSchema={pageTwoValidationSchema}>
          <PageTwo />
        </WizardStep>
      </Wizard>
      <p className={styles.footer}>
        <Link to="/auth/signin">Have an account? Sign In</Link>
      </p>
    </div>
  );
}

export default HirerSignup;
