import React from "react";
import styles from "./otherfooter.module.scss";
import { Link, useHistory } from "react-router-dom";
import Button from "Components/Button";
import logo from "Assets/svg/footerLogo.svg";

interface Props {}

const OtherFooter = (props: Props) => {
  const history = useHistory();

  return (
    <footer className={styles.footer}>
      <section className={styles.top}>
        <div>
          Our platform allows businesses to hire talents whose skills have been
          verified through our interview and screening process.
        </div>
        <Button
          text="Hire A Freelancer"
          red
          chev
          small
          action={() => history.push("/auth/hire-talent")}
        />
      </section>
      <section className={styles.foot}>
        <div className={styles.copyright}>
          <img src={logo} alt="workova logo" />
          <span>Copyright 2020, Workova, LLC</span>
        </div>

        <div className={styles.links}>
          <Link to="#"> Privacy Policy</Link>
          <Link to="#"> Terms & Conditions</Link>
        </div>
      </section>
    </footer>
  );
};

export default OtherFooter;
