import React from "react";
import style from "./textarea.module.scss";

interface Props {
  value: string;
  name: string;
  styles?: Object;
  label?: string;
  action: (e: any) => void;
  margin?: boolean;
}

const Textarea = ({
  name,
  value,
  action,
  label,
  margin,
  styles,
}: Props) => {
  return (
    <div className={`${style.textarea} ${margin ? style.margin : ""}`}>
      {label && <label>{label}</label>}
      {(
        <textarea
          name={name}
          onChange={action}
          value={value}
          style={styles}
        />
      )}
    </div>
  );
};

export default Textarea;
