import React from "react";
import Styles from "./index.module.scss";
import { ReactComponent as ListIcon } from "Assets/svg/list__icon.svg";
import aboutImgTwo from "Assets/images/aboutImg2.png";

const WorkovaAdvantages = () => {
  return (
    <section className={Styles.rowSectionTwo}>
      <div className={Styles.content__container}>
        <div className={Styles.content__main}>
          <div className={Styles.content__tabs}>
            <span></span>
            <span></span>
          </div>

          <h3 className={Styles.content__heading}>Workova advantages</h3>
          <ul className={Styles.content__list}>
            <li>
              <div className={Styles.list__svg__wrapper}>
                <ListIcon height="100%" width="100%" />
              </div>
              <span>
                We provide you with a dedicated project manager to ensure your
                project timelines are met and you get regular updates on the
                status of your projects.
              </span>
            </li>
            <li>
              <div className={Styles.list__svg__wrapper}>
                <ListIcon height="100%" width="100%" />
              </div>
              <span>
                Your Workova dedicated recruitment manager will ensure you get
                the right talents, based on your project and recruitment
                requirements.
              </span>
            </li>
            <li>
              <div className={Styles.list__svg__wrapper}>
                <ListIcon height="100%" width="100%" />
              </div>
              <span>You will have full control over your team.</span>
            </li>
            <li>
              <div className={Styles.list__svg__wrapper}>
                <ListIcon height="100%" width="100%" />
              </div>
              <span>
                You don’t need to worry about the additional expenses that come
                with hiring talents on site.
              </span>
            </li>
            <li>
              <div className={Styles.list__svg__wrapper}>
                <ListIcon height="100%" width="100%" />
              </div>
              <span>
                Our pricing is simple, with no additional or hidden charges.
              </span>
            </li>
            <li>
              <div className={Styles.list__svg__wrapper}>
                <ListIcon height="100%" width="100%" />
              </div>
              <span>
                We offer a secure team option that allows your team to have a
                dedicated workspace and work tools.
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div className={Styles.offSetImg}>
        <img src={aboutImgTwo} alt="room" />
      </div>
    </section>
  );
};

export default WorkovaAdvantages;
