import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import styles from "./style.module.scss";
import logo from "Assets/images/logo.svg";
import Button from "Components/Button";
import Input from "Components/Input";

interface SignState {
  email: string;
}

const ForgotPassword = () => {
  const history = useHistory();

  const [state, setState] = useState<SignState>({ email: "" });

  const setValue = (e: any) =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

  return (
    <div className={styles.forgot}>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="logo" />
      </Link>

      <h3 className={styles.welcome}>Forgot your password?</h3>
      <p className={styles.more}>Please enter your email address, we will send you a reset link</p>
      <form autoComplete="off" className={styles.form}>
        <Input
          value={state.email}
          name="email"
          action={setValue}
          placeholder="Enter your email address"
          label="Email address"
          margin
        />
        <Button
          full
          styles={{
            marginTop: "10%",
            marginBottom: "5%",
          }}
          text="Send Reset Link"
          action={() => null}
        />
      </form>
      <p className={styles.footer}>
        Have an account? <Link to="/auth/signin">Sign In</Link>
      </p>
    </div>
  );
};

export default ForgotPassword;
