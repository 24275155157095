import React from "react";
import Styles from "./index.module.scss";
import Comment from "Components/Comment";
import pic2 from "Assets/images/Ellipse 18.png";

const DisputeDetails = () => {
  return (
    <div>
      <div className={Styles.timeLeft}>
        <p>Time left: 3 hours</p>
      </div>
      <div className={Styles.sectionContainer}>
        <section className={Styles.deliveryTextContainer}>
          <h2>Delivery and timeline for this project</h2>

          <div className={Styles.deliveryTextDetails}>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Voluptatem laudantium ea rerum iure quod, dolor, consectetur nemo
              fugit quos quia atque ad deserunt explicabo, assumenda facere
              fuga! Dolores, nam impedit.
            </p>

            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis
              id omnis accusantium iure iusto voluptas dicta officiis quam
              tenetur, ipsa voluptatum, architecto veniam? Iste eos tenetur ea
              maxime aut sunt?Dolor ipsam pariatur sit. Eveniet assumenda neque
              accusamus dicta! Modi dignissimos nulla similique officiis nobis
              sequi saepe fugit vero, excepturi neque distinctio reprehenderit
              in laudantium, aperiam, unde corrupti rem illum.
            </p>

            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Reiciendis facilis voluptas nisi molestias totam cum, optio maxime
              sint voluptatum nesciunt ea asperiores sequi earum quisquam,
              quaerat ratione repellendus eaque officiis!
            </p>

            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis
              molestias exercitationem quidem illum sit, soluta animi explicabo
              molestiae officia expedita eum iusto rem repudiandae voluptas
              ducimus totam accusantium, voluptatem recusandae?Nemo non
              repellendus, porro possimus ipsum id tempore ab qui tempora
              reiciendis quae. Sit ex delectus fugit rerum voluptate labore?
              Illo at eligendi beatae consectetur atque odit, aliquam vel
              itaque.
            </p>
          </div>
        </section>
        <section className={Styles.activityContainer}>
          <h3>Activities</h3>
          <div className={Styles.activityListContainer}>
            <Comment
              {...{
                name: "Jody Roberts",
                text:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
                time: "11:59pm",
                image: pic2,
              }}
            />
            <Comment
              {...{
                name: "Jody Roberts",
                text:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
                time: "11:59pm",
                image: pic2,
              }}
            />
            <Comment
              {...{
                name: "Jody Roberts",
                text:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
                time: "11:59pm",
                image: pic2,
              }}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default DisputeDetails;
