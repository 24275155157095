import React from "react";
import Select, { components } from "react-select";

import styles from "./index.module.scss";
import closeicon from "Assets/images/close.svg";
import order from "Assets/images/order.svg";
import search from "Assets/images/search.svg";

const colourOptions = [
  { value: "Python", label: "Python" },
  { value: "NodeJs", label: "NodeJs" },
  { value: "PHP", label: "PHP" },
  { value: "ReactJs", label: "ReactJs" },
  { value: "Canva", label: "Canva" },
  {
    value: "Product Development",
    label: "Product Development",
    color: "#FFC400",
  },
  { value: "Ruby", label: "Ruby" },
  { value: "Ruby on Rails", label: "Ruby on Rails" },
  { value: "VueJs", label: "VueJs" },
  { value: "Angular", label: "Angular" },
];

const ProjectSearchBar = () => {
  return (
    <section className={styles.filters}>
      {/* <p>Find Freelancers</p> */}
      <div className={styles.filters__container}>
        <div className={styles.left}>
          <Select
            closeMenuOnSelect={false}
            components={{
              DropdownIndicator: null,
              MultiValueRemove: (props: any) => (
                <components.MultiValueRemove {...props}>
                  <img src={closeicon} alt="close" />
                </components.MultiValueRemove>
              ),
            }}
            defaultValue={[colourOptions[2], colourOptions[3]]}
            isMulti
            name="colors"
            options={colourOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            isClearable={false}
            styles={{
              control: (base) => ({
                border: "none",
              }),
              valueContainer: (base) => ({
                ...base,
                display: "flex",
                flexDirection: "row",
                // flexWrap: "nowrap",
                // overflow: "auto",
                // width: "500px",
              }),
              multiValue: (base) => ({
                ...base,
                fontSize: "15px",
                padding: "8px 8px 8px 12px",
                color: "#04367A",
                backgroundColor: "#EAF3FE",
                borderRadius: "2px",
                margin: "6px",
                minWidth: "100px",
                width: "auto",
                display: "flex",
                justifyContent: "space-between",
              }),
              multiValueRemove: (base) => ({
                ...base,
                height: "10%",
                verticalAlign: "middle",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "10px",
                marginTop: "1px",
                backgroundColor: "transparent !important",
              }),
            }}
          />
        </div>
        <div className={styles.right}>
          <div className={styles.orders}>
            <button>
              <img src={order} alt="" />
              Select Payout range
            </button>
          </div>
          <button>
            <img src={search} alt="" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default ProjectSearchBar;
