import React from "react";
import style from "./comment.module.scss";

interface Props {
  text: string;
  name: string;
  time: string;
  image: string;
  altVersion?: boolean;
}

const Comment = ({ text, name, time, image, altVersion = false }: Props) => {
  return (
    <div className={style.comment}>
      <div className={style.top}>
        <div className={style.topImg}>
          <img src={image} alt="speaker" />
        </div>
        {altVersion ? (
          <div className={`${style.side} ${style.altVersion}`}>
            <p className={style.name}>{name}</p>
            <p className={style.time}>{time}</p>
          </div>
        ) : (
          <div className={style.side}>
            <p className={style.name}>{name}</p>
            <p className={style.time}>{time}</p>
          </div>
        )}
      </div>
      <p className={style.body}>{text}</p>
    </div>
  );
};

export default Comment;
