import React, { ElementType, FunctionComponent } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AllProject from './AllProjects';
import ProjectDetails from './OneProject';

interface RouteElement {
  path: string;
  exact: boolean;
  Child: ElementType;
}

const routes: RouteElement[] = [
  {
    path: "",
    exact: true,
    Child: AllProject,
  },
  {
    path: ":id",
    exact: false,
    Child: ProjectDetails,
  },
];

const Projects: FunctionComponent = () => {
  const { path: pathName } = useRouteMatch();

  return (
    <Switch>
      {routes.map(({ path, exact, Child }, index) => (
        <Route
          exact={exact}
          path={`/projects/${path}`}
          key={index}
        >
          <Child />
        </Route>
      ))}
    </Switch>
  );
};

export default Projects;
