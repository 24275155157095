import React from "react";
import style from "./projectCard.module.scss";
import loc from "Assets/images/projectcard/Vector.png";

export interface CardItem {
  image: string;
  title: string;
  due?: string;
  author: string;
  name: string;
  location: string;
  price?: string;
  text?: string;
  action?: () => void;
  styles?: Object;
  straight?: boolean;
  row?: boolean;
}

const ProjectCard = ({
  image,
  title,
  due,
  author,
  name,
  location,
  price,
  text,
  action,
  styles,
  straight,
  row,
}: CardItem) => {
  return (
    <div className={style.card} onClick={action} style={styles}>
      <div className={style.imageWrapper}>
        <img
          className={`${straight ? style.straight : ""}`}
          src={image}
          alt="project"
        />
      </div>
      <div>
        <p className={style.text}>{title}</p>
        <p className={style.subtext}>{text}</p>
        <div className={style.bottom}>
          <div className={`${style.side} ${row ? style.row : ""}`}>
            <div className={style.pic}>
              <img src={author} alt="" />
            </div>
            <div className={style.none}>
              <p className={style.name}>{name}</p>
              <p className={style.location}>
                <img className={style.locate} src={loc} alt="here" />
                {location}
              </p>
            </div>
          </div>
          {due ? <p className={style.due}>{due}</p> : null}
          {price ? <p className={style.price}>{price}</p> : null}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
