import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllProjects,
  getProjectDetails,
} from "Store/actions/allProjects.action";
import styles from "./oneProject.module.scss";
import Navbar from "Components/Navbar";
import Button from "Components/Button";
import ProjectCard from "Components/ProjectCard";
import OtherFooter from "Components/OtherFooter";
import dots from "Assets/images/dots.png";
import pro1 from "Assets/images/projectcard/Rectangle 9 (4).png";
import loc from "Assets/images/projectcard/Vector.png";
import price from "Assets/images/suitcase.png";
import date from "Assets/images/date.png";
import authorPic from "Assets/images/Ellipse 17.png";
import { calculateDueDate } from "Utils/helpers";

export default function OneProject() {
  let { id: projectId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { loading, project, projects, error } = useSelector(
    ({ allProjectsReducer }: any) => ({
      allProjects: allProjectsReducer.projects,
      project: allProjectsReducer.project,
      projects: allProjectsReducer.projects,
      loading: allProjectsReducer.loading,
      error: allProjectsReducer.error,
    })
  );

  const slicedProjects = (
    projects: Array<Object>,
    start: number,
    end: number
  ): any => {
    return projects.slice(start, end);
  };

  useEffect(() => {
    dispatch(getProjectDetails(projectId));
    dispatch(getAllProjects());
  }, [dispatch]);

  const {
    title,
    images,
    location,
    description,
    payoutAmount,
    dueDate,
    tags,
  } = project;

  return (
    <main className={styles.oneproject}>
      <Navbar />
      {loading ? (
        <div>Loading...</div>
      ) : (
        <section className={styles.top}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.details}>
            <div className={styles.left}>
              <img
                src={images?.length > 1 ? images[0] : pro1}
                alt="Project image"
              />
            </div>
            <div className={styles.right}>
              <p className={styles.location}>
                <img src={loc} alt="location" />
                {location || "Sweden, Stockholm"}
              </p>
              <p className={styles.body}>{description}</p>
              <div className={styles.under}>
                <div className={styles.price}>
                  <img src={price} alt="price" />
                  <p>${payoutAmount}</p>
                </div>
                <p className={styles.datedue}>
                  <img src={date} alt="due date" />
                  Due by {calculateDueDate(dueDate)}
                </p>
              </div>
              <div className={styles.tag}>
                {tags
                  ? tags.map((tag: string, index: number) => (
                      <p key={index} className={styles.tags}>
                        {tag}
                      </p>
                    ))
                  : null}
              </div>
              <div className={styles.action}>
                <Button
                  text="Apply as a Freelancer"
                  small
                  action={() => history.push("/auth/signin")}
                  styles={{
                    padding: "15px 40px",
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      )}

      <section className={styles.middle}>
        <h3>More projects</h3>
        <div className={styles.otherprojects}>
          {slicedProjects(projects, 0, 3).map((project: any, index: number) => (
            <ProjectCard
              key={index}
              action={() => history.push(`/projects/${project._id}`)}
              image={project.images.length ? project.image[0] : pro1}
              title={project.title}
              price={`$${project.payoutAmount}`}
              author={authorPic}
              name="Jody Booms"
              text={project.description}
              location="USA"
              straight
            />
          ))}
        </div>
        <img className={styles.dots} src={dots} alt="pagination dots" />
      </section>
      <OtherFooter />
    </main>
  );
}
