import React, { FunctionComponent } from "react";
import { ISkillItem } from "../../models";
import Styles from "./index.module.scss";

const SkillIcon: FunctionComponent<ISkillItem> = ({ skillLogo, name, small }) => {
  return (
    <div className={small ? Styles.small : Styles.container}>
      <div className={small ? Styles.small__image : Styles.container__image}>
        <img src={skillLogo} alt="skill" />
      </div>
      <p className={small ? Styles.small__title : Styles.container__title}>{name}</p>
    </div>
  );
};

export default SkillIcon;
