import React, { ElementType, useEffect } from "react";
import SideBar from "Components/SideBar";
import styles from "./dashboard.module.scss";
import { Switch, Route } from "react-router-dom";
import DashNav from "Components/DashNav";
import Projects from "./Projects";
import Workspace from "./Workspaces";
import MainDashboard from "./MainDashboard";
import VideoCalls from "./VideoCalls";
import { useSelector, useDispatch } from "react-redux";
import actionCreator from "Utils/actionCreator";
import NewProjectUpdate from "./Projects/Project/NewProjectUpdate";
import UpdateRequirements from "./Projects/Project/UpdateRequirements";

interface Props {}
const Text = ({ name }: { name: string }) => (
  <div style={{ height: "300vh" }}>
    <h1>{name}</h1>
  </div>
);
const routes = [
  {
    path: "main",
    exact: false,
    Child: MainDashboard,
    title: "Dashboard",
  },
  {
    path: "projects/:id/updates/new",
    exact: false,
    Child: NewProjectUpdate,
    title: "New Project Update",
  },
  {
    path: "projects/:id/requirements/update",
    exact: false,
    Child: UpdateRequirements,
    title: "Update project requirements",
  },
  {
    path: "projects",
    exact: false,
    Child: Projects,
    title: "Projects",
  },
  {
    path: "workspaces",
    exact: false,
    Child: Workspace,
    title: "Workspaces",
  },
  {
    path: "recruiters",
    exact: true,
    Child: Text,
    title: "recruiters",
  },
  {
    path: "freelancers",
    exact: false,
    Child: Projects,
    title: "freelancers",
  },
  {
    path: "skills",
    exact: true,
    Child: Text,
    title: "skills",
  },
  {
    path: "interview-request",
    exact: true,
    Child: Text,
    title: "interview request",
  },
  {
    path: "interview",
    exact: true,
    Child: Text,
    title: "interviews",
  },
  {
    path: "connections",
    exact: true,
    Child: Text,
    title: "connections",
  },
  {
    path: "video-calls",
    exact: false,
    Child: VideoCalls,
    title: "video calls",
  },
  {
    path: "courses",
    exact: true,
    Child: Text,
    title: "courses",
  },
  {
    path: "invites",
    exact: true,
    Child: Text,
    title: "invite a friend",
  },
  {
    path: "feedback",
    exact: true,
    Child: Text,
    title: "feedback",
  },
  {
    path: "product-updates",
    exact: true,
    Child: Text,
    title: "product updates",
  },
];

const Dashboard = (props: Props) => {
  const dispatch = useDispatch();
  const networkOnline = useSelector(
    ({ networkReducer }: any) => networkReducer.networkOnline
  );

  useEffect(() => {
    dispatch(actionCreator("NETWORK", navigator.onLine));
    window.addEventListener("offline", (e) => {
      dispatch(actionCreator("NETWORK", false));
    });
    window.addEventListener("online", (e) => {
      dispatch(actionCreator("NETWORK", true));
    });
    return () => {
      window.removeEventListener("offline", () => null);
      window.removeEventListener("online", () => null);
    };
  }, [dispatch]);

  return (
    <>
      {/* {!networkOnline && ( */}
      <div className={`${styles.offline} ${!networkOnline ? styles.show : ""}`}>
        currently offline... waiting for network
      </div>
      {/* )} */}
      <div className={styles.dashboard}>
        <SideBar />
        <div className={styles.container}>
          <Switch>
            {routes.map(
              (
                {
                  path,
                  exact,
                  Child,
                  title,
                }: {
                  path: string;
                  exact: boolean;
                  Child: ElementType;
                  title: string;
                },
                index
              ) => (
                <Route exact={exact} path={`/dashboard/${path}`} key={index}>
                  <DashNav name={title} />
                  <div className={styles.inner}>{<Child name={title} />}</div>
                </Route>
              )
            )}
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
