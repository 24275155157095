

interface NetworkProps {
  networkOnline: boolean;
}
const initialState: NetworkProps = {
  networkOnline: false
};

export default function networkReducer(state = initialState, action: any): any {
  switch (action.type) {
    case 'NETWORK':
      return { ...state, networkOnline: action.payload };


    default:
      return state;
  }
}
