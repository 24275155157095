import React from "react";
import style from "./workspace.module.scss";
import loc from "Assets/images/projectcard/Vector.png";

export interface CardItem {
  image: string;
  title: string;
  time: string;
  status: string;
  location: string;
  action?: () => void;
  styles?: Object;
  straight?: boolean;
}

const WorkspaceCard = ({
  image,
  title,
  time,
  status,
  location,
  action,
  styles,
  straight,
}: CardItem) => {
  return (
    <div className={style.card} onClick={action} style={styles}>
      <img
        className={`${straight ? style.straight : ""}`}
        src={image}
        alt="workspace"
      />
      <div className={style.bottom}>
        <div className={style.left}>
          <p className={style.text}>{title}</p>
          <p className={style.location}>
            <img className={style.locate} src={loc} alt="here" />
            {location}
          </p>
        </div>
        <div className={style.right}>
          <p className={style.name}>{status}</p>
          <p className={style.due}>{time}</p>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceCard;
