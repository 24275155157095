import React, { useState, useRef } from "react";
import chat from "Assets/images/chat.svg";
import briefcase from "Assets/images/briefcase.svg";
import doublecheck from "Assets/images/doublecheck.svg";
// import closeicon from "Assets/images/close.svg";
import order from "Assets/images/order.svg";
// import search from "Assets/images/search.svg";
import chev from "Assets/images/chev-right.svg";
import chevblue from "Assets/images/chevblue.svg";
import desk from "Assets/images/desk.svg";
import plane from "Assets/images/plane.svg";
import star2 from "Assets/images/star2.svg";
import legal from "Assets/images/legal.svg";
import playBtn from "Assets/images/playBtn.svg";
import aiimg from "Assets/images/aiimg.png";
import flagBlue from "Assets/images/flag2.svg";
import flagBlue2 from "Assets/images/flag3.svg";
// import Select, { components } from "react-select";
import Navbar from "Components/Navbar";
import TalentCard, { TalentItem } from "Components/TalentCard";
import styles from "./style.module.scss";
import { Link, useHistory } from "react-router-dom";
import BackCard, { CardItem } from "Components/BackCard";
import leftarrCircle from "Assets/images/leftarrCircleBlue.svg";
import rightarrCircle from "Assets/images/rightarrCircleBlue.svg";
import pic1 from "Assets/images/Group276.png";
import pic2 from "Assets/images/Group277.png";
import pic3 from "Assets/images/Group278.png";
import pic4 from "Assets/images/Group279.png";
import img1 from "Assets/images/Group281.png";
import back from "Assets/images/cardback.svg";
import authorPic from "Assets/images/Ellipse 17.png";
import Button from "Components/Button";
import OtherFooter from "Components/OtherFooter";
import ValueTab from "Components/ValueTab";
import { useSelector } from "react-redux";
import TalentSearchBar from "Components/TalentSearchBar";

const ProjectArray: CardItem[] = [
  {backImg: back, date: "April 4", title: "The new musical app in the industry", image: authorPic, name: "Adeosun Babra", country: "Brazil" },
  {backImg: img1, date: "June 6", title: "A new AI app in the industry", image: authorPic, name: "Adeosun Babra", country: "USA" },
  {backImg: back, date: "January 14", title: "A new AI app in the industrial sector", image: authorPic, name: "Adeosun Babra", country: "USA" },
  {backImg: img1, date: "June 6", title: "A new AI app in the industry", image: authorPic, name: "Adeosun Babra", country: "USA" },
];

export const colourOptions = [
  { value: "Python", label: "Python" },
  { value: "NodeJs", label: "NodeJs" },
  { value: "PHP", label: "PHP" },
  { value: "ReactJs", label: "ReactJs" },
  { value: "Canva", label: "Canva" },
  {
    value: "Product Development",
    label: "Product Development",
    color: "#FFC400",
  },
  { value: "Ruby", label: "Ruby" },
  { value: "Ruby on Rails", label: "Ruby on Rails" },
  { value: "VueJs", label: "VueJs" },
  { value: "Angular", label: "Angular" },
];

export default function Home() {
  const history = useHistory();
  const [eventCard, setEventCard] = useState(0);
  const [current, setCurrent] = useState(0);
  let caroRef = useRef<HTMLDivElement>(null)
  const user = useSelector(({ userReducer }: any) => userReducer.user);

  const theAction = () => {
    user.email ? history.push("/talent/1") : history.push("/auth/signin");
  };

  const searchResult = () => history.push("/talent/search");

  const TalentArray: TalentItem[] = [
    { image: pic1, name: "Pablo Escobar", badge: true, title: "Product Manager", country: "Colombia", action: theAction },
    { image: pic2, name: "Pablo Escobar", badge: true, title: "Product Manager", country: "Colombia", action: theAction },
    { image: pic3, name: "Pablo Escobar", badge: true, title: "Product Manager", country: "Colombia", action: theAction },
    { image: pic4, name: "Pablo Escobar", badge: true, title: "Product Manager", country: "Colombia", action: theAction },
  ];

  const prev = () => {
    caroRef &&  caroRef.current && caroRef.current.scrollTo({
      behavior: 'smooth',
      top: 0,
      left: caroRef.current.scrollLeft - caroRef.current.clientWidth * 0.5
    })
  }

  const next = () => {
    caroRef &&  caroRef.current && caroRef.current.scrollTo({
      behavior: 'smooth',
      top: 0,
      left: caroRef.current.scrollLeft + caroRef.current.clientWidth * 0.5
    })
  }

  return (
    <div className={styles.home}>
      <Navbar />
      <section className={styles.banner}>
        <h1>
          Search, interview and hire <br />
          verified freelancers
        </h1>
        <p>
          We connect highly skilled talents with businesses to enable them to
          get high paying jobs, share their workload and earn a steady income in
          a productive workspace
        </p>
        <div className={styles.hire}>
          <Link to="/auth/hire-talent">
            Hire a Talent <img src={chev} alt="chev icon" />
          </Link>
          <Link to="/auth/hire-talent" className={styles.link}>
            Start earning as a freelancer <img src={chev} alt="chev icon" />
            <img src={chev} alt="chev icon" />
          </Link>
        </div>
      </section>
      {user.email && (
        <section className={styles.filters}>
          <p>Find Freelancers</p>
          <TalentSearchBar action={searchResult}/>
        </section>
      )}
      <section className={styles.talents}>
        <h1>Highly skilled and verified talents</h1>
        <ValueTab
          tabs={[
            {
              name: "Developers",
              component: () => (
                <div className={styles.verified}>
                  {TalentArray.map((talents, index) => (
                    <TalentCard {...talents} key={index} />
                  ))}
                </div>
              ),
              home: true,
            },
            {
              name: "Designers",
              component: () => (
                <div className={styles.verified}>
                  {TalentArray.map((talents, index) => (
                    <TalentCard {...talents} key={index} />
                  ))}
                </div>
              ),
              home: true,
            },
            {
              name: "Product Managers",
              component: () => (
                <div className={styles.verified}>
                  {TalentArray.map((talents, index) => (
                    <TalentCard {...talents} key={index} />
                  ))}
                </div>
              ),
              home: true,
            },
            {
              name: "Project Managers",
              component: () => (
                <div className={styles.verified}>
                  {TalentArray.map((talents, index) => (
                    <TalentCard {...talents} key={index} />
                  ))}
                </div>
              ),
              home: true,
            },
            {
              name: "Digital Marketers",
              component: () => (
                <div className={styles.verified}>
                  {TalentArray.map((talents, index) => (
                    <TalentCard {...talents} key={index} />
                  ))}
                </div>
              ),
              home: true,
            },
            {
              name: "Content Creators",
              component: () => (
                <div className={styles.verified}>
                  {TalentArray.map((talents, index) => (
                    <TalentCard {...talents} key={index} />
                  ))}
                </div>
              ),
              home: true,
            },
          ]}
        />
      </section>
      <section className={styles.projects}>
        <div className={styles.left}>
          <h1>
            Really amazing and interesting projects that solve realife problems
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis
            aute irure dolor in reprehenderit in voluptate velit esse cillum.
          </p>
          <Button
            text="View All Projects"
            action={() => history.push("projects")}
            chev
            medium
            styles={{
              fontSize: "14px",
              padding: "15px 40px",
            }}
          />
        </div>
        <div className={styles.right}>
          <footer>
            <span>
              <button onClick={prev}>
                <img src={leftarrCircle} alt="left button" />
              </button>
              <button onClick={next}>
                <img src={rightarrCircle} alt="right button" />
              </button>
            </span>
          </footer>
          <div className={styles.carousel}>
            <main ref={caroRef} className={styles.try}>
              {ProjectArray.map((projects1, i) => (
                <BackCard {...projects1} key={i} />
              ))}
            </main>
          </div>
        </div>
      </section>
      <section className={styles.talentHire}>
        <h1>Hire Talents Easily</h1>
        <main>
          <div className={styles.item}>
            <img src={chat} alt="talk icon" />
            <h2>
              Talk To One of Our <br /> Industry Experts
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolo
            </p>
          </div>
          <div className={styles.item}>
            <img src={briefcase} alt="talk icon" />
            <h2>
              Work With a <br />
              Verified Talent
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut
            </p>
          </div>
          <div className={styles.item}>
            <img src={doublecheck} alt="talk icon" />
            <h2>
              Your Project Done <br />
              and delivered
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolo
            </p>
          </div>
        </main>
        <Button
          text="Hire A Freelancer"
          action={() => history.push("auth/hire-talent")}
          chev
          medium
          red
          styles={{
            fontSize: "14px",
            padding: "15px 40px",
          }}
        />
      </section>
      <section className={styles.successVideo}>
        <div className={styles.left}>
          <div className={styles.card}>
            <div className={styles.backdrop}>
              <img src={aiimg} alt="background" />
            </div>
            <div className={styles.content}>
              <h2>Scaling Revenue With Marketing Automation and AI Engines</h2>

              <div className={styles.below}>
                <div className={styles.profile}>
                  {/* <div className={styles.avatar}></div> */}
                  <img className={styles.avatar} src={authorPic} alt="" />
                  <div className={styles.user}>
                    <h4>Whizney White</h4>
                    <div className={styles.details}>
                      <img src={flagBlue} alt="flag icon" />
                      <span>USA</span>
                      <span>June 6</span>
                    </div>
                  </div>
                </div>
                <button className={styles.btn}>
                  <img src={playBtn} alt="play icon" /> Watch The Video
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <h1>
            People create stories <br /> after successful work
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis
            aute irure dolor in reprehenderit in voluptate velit esse cillum.
          </p>
          <Button
            text="More Success Stories"
            action={() => null}
            chev
            styles={{
              fontSize: "14px",
              padding: "15px 40px",
            }}
          />
        </div>
      </section>
      <section className={styles.whyus}>
        <div className={styles.left}>
          <h1>
            Why the Workova <br /> platform is preferred
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis
            aute irure dolor in reprehenderit in voluptate velit esse cillum.
          </p>

          <div className={styles.foot}>
            <Button
              text="Hire a freelancer"
              action={() => history.push("auth/hire-talent")}
              red
              chev
              styles={{
                fontSize: "14px",
                padding: "15px 40px",
              }}
            />
            <Link to="auth/signup">
              Start earning as a freelancer
              <img src={chevblue} alt="chevron icon" />
              <img src={chevblue} alt="chevron icon" />
            </Link>
          </div>
        </div>

        <div className={styles.right}>
          <div className={`${styles.smCard}`}>
            <img src={desk} alt="desk icon" />
            <p>Workspaces with steady power supply</p>
          </div>
          <div className={`${styles.smCard}`}>
            <img src={star2} alt="star icon" />
            <p>Workspaces with steady power supply</p>
          </div>
          <div className={`${styles.smCard}`}>
            <img src={legal} alt="legal icon" />
            <p>Workspaces with steady power supply</p>
          </div>
          <div className={`${styles.smCard}`}>
            <img src={plane} alt="plane icon" />
            <p>Workspaces with steady power supply</p>
          </div>
        </div>
      </section>
      <section className={styles.ready}>
        <h1>Ready to start?</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt voluptate velit esse cillum.
        </p>
        <Button
          text="Hire a freelancer"
          action={() => history.push("auth/hire-talent")}
          red
          chev
          styles={{
            fontSize: "14px",
            padding: "15px 40px",
          }}
        />
      </section>
      <section className={styles.company}>
        <div className={styles.left}>
          <div className={styles.cards}>
            <div className={`${styles.smCard}`}>
              <h1>COMPANY</h1>
              <span>
                <img src={flagBlue} alt="flag icon" />
                USA
              </span>
            </div>
            <div className={`${styles.smCard}`}>
              <h1>COMPANY</h1>
              <span>
                <img src={flagBlue2} alt="flag icon" />
                USA
              </span>
            </div>
            <div className={`${styles.smCard}`}>
              <h1>COMPANY</h1>
              <span>
                <img src={flagBlue2} alt="flag icon" />
                France
              </span>
            </div>
            <div className={`${styles.smCard}`}>
              <h1>COMPANY</h1>
              <span>
                <img src={flagBlue} alt="flag icon" />
                Nigeria
              </span>
            </div>
          </div>
          <footer>
            <span>
              <button onClick={prev}>
                <img src={leftarrCircle} alt="left button" />
              </button>
              <button onClick={next}>
                <img src={rightarrCircle} alt="right button" />
              </button>
            </span>
          </footer>
        </div>
        <div className={styles.right}>
          <h1>
            Trusted by <br />
            recruiters, businesses <br />
            and startups
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
      </section>
      {/* <section className={styles.courses}>
        <h1>Digest knowledge from our courses</h1>
      </section>
      <section className={styles.events}>
        <div className={styles.top}>
          <h1>Events</h1>
          <Button text="More Events" chev action={() => null} />
        </div>
        <div
          className={`${styles.cards} ${eventCard === 1 ? styles.cards2 : ""} ${
            eventCard === 2 ? styles.cards3 : ""
          }`}
        >
          <div onMouseEnter={() => setEventCard(0)} className={styles.card}>
            <div className={styles.image}></div>
            <div className={styles.content}>
              <span>Tech Event</span>
              <h2>
                The gathering of Highly skilled Developers under a roof: Boom!
              </h2>
            </div>
          </div>
          <div onMouseEnter={() => setEventCard(1)} className={styles.card}>
            <div className={styles.image}></div>
            <div className={styles.content}>
              <span>Tech Event</span>
              <h2>
                The gathering of Highly skilled Developers under a roof: Boom!
              </h2>
            </div>
          </div>
          <div onMouseEnter={() => setEventCard(2)} className={styles.card}>
            <div className={styles.image}></div>
            <div className={styles.content}>
              <span>Tech Event</span>
              <h2>
                The gathering of Highly skilled Developers under a roof: Boom!
              </h2>
            </div>
          </div>
        </div>
      </section> */}
      <section className={styles.allTalents}>
        <h1>Workova Talents</h1>

        <div className={styles.lists}>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">ReactJs Developers</Link>
            </li>
            <li>
              <Link to="#">NodeJs Developers</Link>
            </li>
            <li>
              <Link to="#">Angular Developers</Link>
            </li>
            <li>
              <Link to="#">Product Managers</Link>
            </li>
            <li>
              <Link to="#">C# Engineers</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">ReactJs Developers</Link>
            </li>
            <li>
              <Link to="#">NodeJs Developers</Link>
            </li>
            <li>
              <Link to="#">Angular Developers</Link>
            </li>
            <li>
              <Link to="#">Product Managers</Link>
            </li>
            <li>
              <Link to="#">C# Engineers</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">ReactJs Developers</Link>
            </li>
            <li>
              <Link to="#">NodeJs Developers</Link>
            </li>
            <li>
              <Link to="#">Angular Developers</Link>
            </li>
            <li>
              <Link to="#">Product Managers</Link>
            </li>
            <li>
              <Link to="#">C# Engineers</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">ReactJs Developers</Link>
            </li>
            <li>
              <Link to="#">NodeJs Developers</Link>
            </li>
            <li>
              <Link to="#">Angular Developers</Link>
            </li>
            <li>
              <Link to="#">Product Managers</Link>
            </li>
            <li>
              <Link to="#">C# Engineers</Link>
            </li>
          </ul>
        </div>
        <div className={styles.seperator} />
        <div className={styles.lists}>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">ReactJs Developers</Link>
            </li>
            <li>
              <Link to="#">NodeJs Developers</Link>
            </li>
            <li>
              <Link to="#">Angular Developers</Link>
            </li>
            <li>
              <Link to="#">Product Managers</Link>
            </li>
            <li>
              <Link to="#">C# Engineers</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">ReactJs Developers</Link>
            </li>
            <li>
              <Link to="#">NodeJs Developers</Link>
            </li>
            <li>
              <Link to="#">Angular Developers</Link>
            </li>
            <li>
              <Link to="#">Product Managers</Link>
            </li>
            <li>
              <Link to="#">C# Engineers</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">ReactJs Developers</Link>
            </li>
            <li>
              <Link to="#">NodeJs Developers</Link>
            </li>
            <li>
              <Link to="#">Angular Developers</Link>
            </li>
            <li>
              <Link to="#">Product Managers</Link>
            </li>
            <li>
              <Link to="#">C# Engineers</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">ReactJs Developers</Link>
            </li>
            <li>
              <Link to="#">NodeJs Developers</Link>
            </li>
            <li>
              <Link to="#">Angular Developers</Link>
            </li>
            <li>
              <Link to="#">Product Managers</Link>
            </li>
            <li>
              <Link to="#">C# Engineers</Link>
            </li>
          </ul>
        </div>
      </section>
      <OtherFooter />
    </div>
  );
}
