import React, { useEffect } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Styles from "./projectDetail.module.scss";
import Checkbox from "Components/Checkbox";
import Button from "Components/Button";
import message from "Assets/images/message.svg";
import banner from "Assets/images/bann2.png";
import calendar from "Assets/images/calendar.svg";
import { getProjectDetails } from "Store/actions/project.action";
import { Project } from "./interfaces";
import { calculateDueDate } from "Utils/helpers";

interface Props {}

const ProjectDetailContent = (props: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const route = useRouteMatch();

  const { id } = useParams();

  const { loading, project, user, networkOnline } = useSelector(
    ({ projectReducer, userReducer, networkReducer }: any) => ({
      loading: projectReducer.loading,
      project: projectReducer.singleProject as Project,
      user: userReducer.user,
      networkOnline: networkReducer.networkOnline,
    })
  );

  useEffect(() => {
    if (networkOnline) {
      dispatch(getProjectDetails(id, user.accounts));
    }
  }, [networkOnline, dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const image =
    project.images && project.images.length ? project.images[0] : banner;

  return (
    <div className={Styles.projectDetail}>
      <div className={Styles.actions}>
        <Button
          small
          invert
          text="Mark As Completed"
          action={() => null}
          styles={{
            width: "15%",
            marginRight: "15px",
          }}
        />
        <Button
          small
          text="Assign to Talent"
          action={() => null}
          styles={{
            width: "15%",
          }}
        />
      </div>
      <section>
        <div className={Styles.item}>
          <div className={Styles.topImg}>
            <img src={image} alt="banner" />
          </div>
          <h1>{project.title}</h1>
          <p>{project.description}</p>
        </div>
        <div className={`${Styles.item} ${Styles.left}`}>
          <h2>Project number: #{project.projectNumber}</h2>
          <section>
            <main>
              <img src={calendar} alt="calendar" />
              <div>
                <span>Project is due by</span>
                <h1>{calculateDueDate(project.dueDate)}</h1>
              </div>
              <Button
                small
                text="Schedule Delivery Call"
                action={() => null}
                styles={{ padding: "14px 35px", alignSelf: "flex-start" }}
              />
            </main>
            <main className={Styles.green}>
              <img src={message} alt="message" />
              <div>
                <span>Open new project</span>
                <h1>Dispute</h1>
              </div>
              <Button
                small
                text="Open A Dispute"
                action={() => history.push("/dashboard/projects/1/newdispute")}
                green
                styles={{ padding: "14px 35px", alignSelf: "flex-start" }}
              />
            </main>
          </section>

          <div className={`${Styles.card} ${Styles.compact}`}>
            <p className={Styles.title}>Skills required for this project</p>
            <div className={Styles.pills}>
              {project.requiredSkills &&
                project.requiredSkills.map((skill) => (
                  <div key={skill._id} className={Styles.pill}>
                    {skill.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className={Styles.divider}></div>
      <section>
        <div className={Styles.item}>
          <div className={Styles.card}>
            <p className={Styles.title}>Project delivery expectations</p>
            <div className={Styles.checks}>
              {project.deliveryExpectation &&
                project.deliveryExpectation.map((item, id) => (
                  <Checkbox key={id} label={item} value={true} />
                ))}
            </div>
            <p className={Styles.title}>Additional delivery expectations</p>
            <p>{project.additionalDeliveryExpectation}</p>
          </div>
        </div>
        <div className={`${Styles.item} ${Styles.left}`}>
          <div className={Styles.card}>
            <p className={Styles.title}>Project requirements</p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
              ex ea commodo consequat.
            </p>

            <div className={Styles.foot}>
              <Button
                text="Update Requirements"
                action={() => history.push(`${route.url}/requirements/update`)}
                invert
                styles={{ padding: "12px 15px", fontSize: "13px" }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProjectDetailContent;
