import {
  GET_ALL_PROJECTS,
  GET_ALL_PROJECTS_SUCCESS,
  GET_ALL_PROJECTS_FAILURE,
  GET_ONE_PROJECT_DETAILS,
  GET_ONE_PROJECT_DETAILS_SUCCESS,
  GET_ONE_PROJECT_DETAILS_FAILURE,
} from "../constants/project.contants";

interface AllProjectsProps {
  projects: Object;
  project: Object | null;
  loading: boolean;
  error: Object | null;
}

const initialState: AllProjectsProps = {
  projects: [],
  project: {},
  loading: false,
  error: null,
};

const allProjectsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ALL_PROJECTS:
      return { ...state, loading: true };

    case GET_ALL_PROJECTS_SUCCESS:
      return { ...state, loading: false, projects: action.payload };

    case GET_ALL_PROJECTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case GET_ONE_PROJECT_DETAILS:
      return { ...state, loading: true, error: action.payload };

    case GET_ONE_PROJECT_DETAILS_SUCCESS:
      return { ...state, loading: false, project: action.payload };

    case GET_ONE_PROJECT_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.paylod };

    default:
      return state;
  }
};

export default allProjectsReducer;
