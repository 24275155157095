import { combineReducers } from "redux";
import projectReducer from "./project.reducer";
import userReducer from "./user.reducer";
import networkReducer from "./network.reducer";
import skillReducer from "./skill.reducer";
import allProjectsReducer from "./allProjects.reducer";

const rootReducer = combineReducers({
  projectReducer,
  userReducer,
  networkReducer,
  skillReducer,
  allProjectsReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
