export const USER_PROJECTS_REQUEST = "USER_PROJECTS_REQUEST";
export const USER_PROJECTS_SUCCESS = "USER_PROJECTS_SUCCESS";
export const USER_PROJECTS_FAILURE = "USER_PROJECTS_FAILURE";

export const NEW_PROJECTS_REQUEST = "NEW_PROJECTS_REQUEST";
export const NEW_PROJECTS_SUCCESS = "NEW_PROJECTS_SUCCESS";
export const NEW_PROJECTS_FAILURE = "NEW_PROJECTS_FAILURE";

export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILURE = "GET_PROJECT_FAILURE";

export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const GET_ALL_PROJECTS_SUCCESS = "GET_ALL_PROJECTS_SUCCESS";
export const GET_ALL_PROJECTS_FAILURE = "GET_ALL_PROJECTS_FAILURE";

export const GET_ONE_PROJECT_DETAILS = "GET_ONE_PROJECT_DETAILS";
export const GET_ONE_PROJECT_DETAILS_SUCCESS = "GET_ONE_PROJECT_DETAILS_SUCCESS";
export const GET_ONE_PROJECT_DETAILS_FAILURE = "GET_ONE_PROJECT_DETAILS_FAILURE";