import React, { useState } from "react";
import Styles from "./contract.module.scss";
import Button from "Components/Button";
import Textarea from "Components/Textarea";
import Comment from "Components/Comment";
import pic1 from "Assets/images/Ellipse 17.png";
import pic2 from "Assets/images/Ellipse 18.png";

interface Review {
  review: string;
}

const ContractContent = () => {
  const [state, setState] = useState<Review>({ review: "" });

  const setValue = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <div className={Styles.contract}>
      <div className={Styles.actions}>
        <Button
          small
          text="Download"
          action={() => null}
          styles={{
            color: "#0251bc",
            background: "#e2f6ff",
            width: "15%",
            marginRight: "10px",
          }}
        />
        <Button
          small
          text="Send Contract"
          action={() => null}
          styles={{
            width: "15%",
          }}
        />
      </div>
      <div className={Styles.content}>
        <div className={Styles.left}>
          <h2>Contract for the API project</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. <br />
            <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            <br />
            <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat.
          </p>
        </div>
        <div className={Styles.right}>
          <div className={Styles.inset}>
            <Textarea
              label="Add a review"
              name="review"
              action={setValue}
              value={state.review}
              margin
              styles={{
                height: "150px"
              }}
            />
            <Button
              small
              text="ADD REVIEW"
              action={() => null}
              styles={{
                color: "#0251bc",
                background: "transparent",
              }}
            />
          </div>
          <div className={Styles.commentarea}>
            <p>Reviews</p>
            <div className={Styles.commments}>
              <Comment
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco."
                name="Jody Roberts"
                time="11:59pm"
                image={pic2}
              />
              <Comment
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                name="Jimmy Don"
                time="11:59pm"
                image={pic1}
              />
              <Comment
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                name="Jimmy Don"
                time="11:59pm"
                image={pic1}
              />
            </div>
            <div className={Styles.accept}>
              <Button
                small
                text="Accept"
                action={() => null}
                styles={{
                  color: "#0251bc",
                  background: "#e2f6ff",
                  width: "30%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractContent;
