import React from "react";
import style from "./style.module.scss";
import del from "Assets/images/delete.svg";
import disk from "Assets/images/disk.svg";
import Button from "Components/Button";

export interface notifItem {
  avatar: string;
  sender: string;
  date: string;
  role: string;
  location: string;
  email: string;
  content: string;
}

const NotifDetail = ({
  avatar,
  sender,
  date,
  role,
  location,
  email,
  content,
}: notifItem) => {
  return (
    <div className={style.detail}>
      <div className={style.top}>
        <p className={style.date}>{date}</p>
        <div className={style.side}>
          <img src={disk} alt="disc" />
          <img src={del} alt="delete" />
        </div>
      </div>
      <div className={style.mid}>
        <div className={style.imgWrapper}>
          <img src={avatar} alt="avatar" />
        </div>
        <div className={style.__side}>
          <div className={style.top}>
            <p className={style.sender}>{sender}</p>
            <p className={style.loc}>
              {location} . {role}
            </p>
          </div>
          <p className={style.mail}>{email}</p>
        </div>
      </div>
      <div className={style.bottom}>
        <p className={style.content}>{content}</p>
        <div className={style.botCard}>
          <p className={style.title}>The Online Bootcamp Project</p>
          <p className={style.body}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <div className={style.make}>
            <Button small text="View Project" action={() => null} styles={{ padding: "10px 25px"}}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotifDetail;
