import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./allWorkspaces.module.scss";
import WorkspaceCard, { CardItem } from "Components/WorkspaceCard";
import Navbar from "Components/Navbar";
import OtherFooter from "Components/OtherFooter";
import WorkspaceSearchBar from "Components/WorkspaceSearchBar";
import img1 from "Assets/images/workspace.png";

export default function Workspace() {
  const history = useHistory();

  const theAction = () => history.push("/workspaces/1");

  const workspaceArray: CardItem[] = [
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction},
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction},
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction},
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction},
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction},
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction},
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction},
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction},
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction},
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction},
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction},
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction},
  ]

  return (
    <main className={styles.allWorkspace}>
      <Navbar />
      <section className={styles.top}>
        <div className={styles.heading}>
          <h1>Workspaces</h1>
        </div>
        <div className={styles.hold}>
          <WorkspaceSearchBar />
        </div>
        <div className={styles.topProjects}>
          {workspaceArray.map((workspace1, index) => (
            <WorkspaceCard {...workspace1} key={index} />
          ))}
        </div>
      </section>
      <OtherFooter />
    </main>
  );
}
