import React from "react";
import style from "./style.module.scss";
import details from "Assets/images/details.svg";
import playInt from "Assets/images/playInterview.svg";

export interface InterviewItem {
  avatar: string;
  name: string;
  time: string;
  message: string;
}

const InterviewCard = ({ avatar, name, time, message }: InterviewItem) => {
  return (
    <div className={style.interview}>
      <img className={style.avatar} src={avatar} alt="invitee pics" />
      <div className={style.details}>
        <div className={style.diff}>
          <p className={style.name}>{name}</p>
          <p className={style.time}>{time}</p>
        </div>
        <p className={style.message}>{message}</p>
      </div>
      <div className={style.more}>
        <img src={details} alt="details"/>
        <img src={playInt} alt="respond"/>
      </div>
    </div>
  );
}

export default InterviewCard;
