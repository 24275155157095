import React from "react";
import styles from "./profilecard.module.scss";
import loc from "Assets/images/projectcard/Vector.png";
import star from "Assets/images/rating.png";
import verify from "Assets/svg/verify.svg";
import favor from "Assets/images/favorite.svg";
import notFavor from "Assets/images/notfavorite.svg";

export interface items {
  image: string;
  name: string;
  email: string;
  title: string;
  location: string;
  rating: string;
  badge?: boolean;
  favorite?: boolean;
}

const ProfileCard = ({
  image,
  title,
  email,
  name,
  location,
  rating,
  badge,
  favorite,
}: items) => {
  return (
    <div className={styles.profilecard}>
      {badge ? <img className={styles.badge} src={verify} alt="verified"/> : null}
      {favorite ? <img className={styles.like} src={favor} alt="liked"/> : <img className={styles.like} src={notFavor} alt=""/>}
      <div className={styles.top}>
        <img src={image} alt="freelancer's face" />
        <p className={styles.name}>{name}</p>
        <p className={styles.mail}>{email}</p>
        <hr />
      </div>
      <div className={styles.bottom}>
        <p className={styles.title}>{title}</p>
        <div className={styles.left}>
          <p className={styles.location}>
            <img src={loc} alt="here" />
            {location}
          </p>
          <div className={styles.rate}>
            <img className={styles.rate} src={star} alt="rating" />
            {rating}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
