import React, { FunctionComponent } from "react";

import Checkbox from "Components/Checkbox";
import { ReactComponent as FilterIcon } from "Assets/svg/filter__icon.svg";
import { ReactComponent as LeftArrow } from "Assets/svg/left__arrow.svg";
import Styles from "./index.module.scss";
import { useHistory, useRouteMatch } from "react-router-dom";

type INavigateToDetailsPage = (id: number | string) => void;

const TableRow: FunctionComponent<{
  date: string;
  title: string;
  message: string;
  comments: string;
  status: string;
  navigateToDetailsPage: INavigateToDetailsPage;
}> = ({ date, title, message, comments, status, navigateToDetailsPage }) => {
  return (
    <tr onClick={() => navigateToDetailsPage("1")}>
      <td>
        <span>
          <Checkbox label="" value={false} onchange={() => {}} />
        </span>
      </td>
      <td>
        <span>{date}</span>
      </td>
      <td>
        <span>{title}</span>
      </td>
      <td>
        <span>{message}</span>
      </td>
      <td>
        <span>{comments}</span>
      </td>
      <td>
        <span
          className={`${
            status === "Pending" ? Styles.pending : Styles.resolved
          }`}
        >
          {status}
        </span>
      </td>
    </tr>
  );
};

const mockData = {
  date: "15 March, 2020",
  title: "Delivery date and Project Timelines",
  message: "Hi, please lets make this very clear. I have some...",
  comments: "20 comments",
  status: "Pending",
};

const mockNum = Array.from(Array(5).keys());

const AllDisputes: FunctionComponent = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const navigateToDetailsPage: INavigateToDetailsPage = (id) => {
    history.push(`${url}/${id}`);
    // console.log(history, "history");
    // console.log(path, "path");
  };

  return (
    <div>
      <div className={Styles.topRow}>
        <button className={Styles.filterButton}>
          <FilterIcon />
          <span>Filter by status</span>
        </button>

        <button className={Styles.openDisputeButton}>Open Dispute</button>
      </div>
      <div className={Styles.tableContainer}>
        <table className={Styles.disputeTable}>
          <thead>
            <tr>
              <th>
                <Checkbox label="" value={false} onchange={() => {}} />
              </th>
              <th>Date</th>
              <th>Title</th>
              <th>Message</th>
              <th>Comments</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {mockNum.map((_, index) => (
              <TableRow
                {...mockData}
                key={index}
                navigateToDetailsPage={navigateToDetailsPage}
              />
            ))}
          </tbody>
        </table>

        <div className={Styles.pagination}>
          <div className={Styles.pagination__numbers}>
            <button>1</button>
            <button>2</button>
            <button>3</button>
            <button>5</button>
            <button>...</button>
          </div>
          <div className={Styles.pagination__arrows}>
            <button>
              <LeftArrow />
            </button>
            <button>
              <LeftArrow />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllDisputes;
