import React, { FunctionComponent, FormEvent, ChangeEvent } from "react";
import styles from "./index.module.scss";

interface ITextAreaWithButton {
  name: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  buttonText: string;
}

const TextAreaWithButton: FunctionComponent<ITextAreaWithButton> = ({
  name,
  value,
  onChange,
  buttonText,
}) => {
  return (
    <div>
      <label htmlFor={`${name}__textarea_id`} className={styles.textareaLabel}>
        <textarea
          name={name}
          id={`${name}__textarea_id`}
          value={value}
          onChange={onChange}
        ></textarea>

        <button className={styles.textareaButton}>{buttonText}</button>
      </label>
    </div>
  );
};

export default TextAreaWithButton;
