import Axios from "axios";
import { initializeSession } from "Store/actions/initial.action";
import actionCreator from "./actionCreator";
import { USER_SIGN_IN_SUCCESS } from "Store/constants/user.contants";
// import { authUserSuccess } from "../store/actions/auth";
// import { fetchProfile, fetchTailorProfile } from "../store/actions/profile";

const setUser = (store: any) => {
  Axios.defaults.baseURL = "http://ensemblec-external-1xguqrz706f8j-1447215973.eu-west-1.elb.amazonaws.com:6204";
  // Axios.defaults.baseURL = "https://9ac7ccfe0c67.ngrok.io";
  let user = null;

  const xtag = sessionStorage.getItem("xtag");
  if (xtag) {
    Axios.defaults.headers.common["x-tag"] = xtag;
  } else {
    store.dispatch(initializeSession());
  }

  const rawUser = localStorage.getItem("user");
  if (rawUser) {
    user = JSON.parse(rawUser);
  }

  if (user) {
    const giroToken = localStorage.getItem("giroToken");
    const workovaJWT = localStorage.getItem("workovaJWT");
    if (giroToken)
      Axios.defaults.headers.common["girotoken"] = giroToken;
    if (workovaJWT)
      Axios.defaults.headers.common["Authorization"] = workovaJWT;

    store.dispatch(actionCreator(USER_SIGN_IN_SUCCESS, user))

  }
};
export default setUser;
