import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import styles from "./style.module.scss";
import logo from "Assets/images/logo.svg";
import Button from "Components/Button";
import Input from "Components/Input";

interface SignState {
  password: string;
}

const ChangePassword = () => {
  const history = useHistory();

  const [state, setState] = useState<SignState>({ password: "" });

  const setValue = (e: any) =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

  return (
    <div className={styles.change}>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="logo" />
      </Link>

      <h3 className={styles.welcome}>Change your password</h3>
      <form autoComplete="off" className={styles.form}>
        <Input
          value={state.password}
          name="password"
          action={setValue}
          placeholder="Choose a password"
          label="New password"
          password
          margin
        />
        <Input
          value={state.password}
          name="password"
          action={setValue}
          placeholder="Retype your new password"
          label="Confirm new password"
          password
          margin
        />
        <Button
          full
          styles={{
            marginTop: "10%",
            marginBottom: "5%",
          }}
          text="Change Password"
          action={() => null}
        />
      </form>
      <p className={styles.footer}>
        Dont have an account? <Link to="/auth/hire-talent">Hire a talent</Link>{" "}
        or <Link to="/auth/signup">Join as a talent</Link>
      </p>
    </div>
  );
};

export default ChangePassword;
