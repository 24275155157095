import React, {
  FunctionComponent,
} from "react";
import Select, { components } from "react-select";
import closeicon from "Assets/images/close.svg";
import Styles from "./index.module.scss";

interface Props {
  options: any[];
  value: any[];
  onChange: (...e: any) => void;
  placeholder: string;
}

const MultiValueSelect: FunctionComponent<Props> = ({ value, options, onChange, placeholder }) => {
  return (
    <>
      <label className={Styles.label}>
        Type a skill or skills required for this project
      </label>
      <Select
        value={value}
        onChange={onChange}
        closeMenuOnSelect={false}
        placeholder={placeholder}
        options={options}
        components={{
          DropdownIndicator: null,
          MultiValueRemove: (props: any) => (
            <components.MultiValueRemove {...props}>
              <img src={closeicon} alt="close" />
            </components.MultiValueRemove>
          ),
        }}
        id="color"
        multi={true}
        onBlur={() => {}}
        isMulti
        name="colors"
        className="basic-multi-select"
        classNamePrefix="select"
        isClearable={false}
        styles={{
          control: (base) => ({
            border: "none",
          }),
          valueContainer: (base) => ({
            ...base,
            display: "flex",
            flexDirection: "row",
            backgroundColor: '#fff',
            border: '1px solid #c9d7eb',
            color: '#73859e',
            borderRadius: '5px',
            width: '100%',
            padding: '15px',
            fontSize: '14px',
            marginTop: '10px',
            outline: 'none',
          }),
          placeholder: (base) => ({
            ...base,
            color: '#b6bfcc',
          }),
          multiValue: (base) => ({
            ...base,
            fontSize: "18px",
            padding: "8px 8px 8px 12px",
            color: "#04367A",
            backgroundColor: "#EAF3FE",
            borderRadius: "2px",
            margin: "0 5px",
            minWidth: "100px",
            width: "auto",
            display: "flex",
            justifyContent: "space-between",
          }),
          multiValueLabel: (base) => ({
            ...base,
            color: '#04367a',
            fontSize: 14,
            whiteSpace: 'nowrap',
            fontWeight: 400,
          }),
          multiValueRemove: (base) => ({
            ...base,
            height: "100%",
            verticalAlign: "middle",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "10px",
            marginTop: "3px",
            backgroundColor: "transparent !important",
          }),
        }}
      />
    </>
  )
}

export default MultiValueSelect;
