import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import styles from "./style.module.scss";
import logo from "Assets/images/logo.svg";
import Button from "Components/Button";
import Input from "Components/Input";
import Checkbox from "Components/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "Store/actions/user.action";

interface SignState {
  email: string;
  password: string;
}

const Signin = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = useState<SignState>({ email: "", password: "" });
  const [remember, setRemember] = useState(false);

  const { loading } = useSelector(({ userReducer }: any) => ({
    loading: userReducer.loading,
  }));

  const setValue = (e: any) =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  const signInAction = (e?: Event) => {
    e && e.preventDefault();
    dispatch(loginUser({ ...state }, () => history.push("/")));
  };
  return (
    <div className={styles.Signin}>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="logo" />
      </Link>

      <h3 className={styles.welcome}>Welcome back! Sign In</h3>

      <form autoComplete="off" className={styles.form}>
        <Input
          value={state.email}
          name="email"
          action={setValue}
          placeholder="Your email address"
          label="Email address"
          margin
        />
        <Input
          value={state.password}
          name="password"
          action={setValue}
          placeholder="Enter password"
          label="Password"
          password
          margin
        />
        <div className={styles.middle}>
          <div>
            <Checkbox
              label="Remember me"
              value={remember}
              onchange={() => setRemember((prev) => !prev)}
            />
          </div>
          <div className={styles.pswd}>
            <Link to="/auth/forgot-password">Forgot password?</Link>
          </div>
        </div>
        <Button
          full
          styles={{
            marginTop: "10%",
            marginBottom: "5%",
          }}
          text="Sign In"
          loading={loading}
          disabled={(!state.email && !state.password) || loading}
          action={signInAction}
        />
      </form>
      <p className={styles.footer}>
        Don't have an account? <Link to="/auth/hire-talent">Hire a talent</Link>{" "}
        or <Link to="/auth/signup">Join as a talent</Link>
      </p>
    </div>
  );
};

export default Signin;
