import React, { useState, FormEvent, ChangeEvent } from "react";
import Comment from "Components/Comment";

import Button from "Components/Button";
import pic2 from "Assets/images/Ellipse 18.png";
import styles from "./index.module.scss";
import TextAreaWithButton from "Components/TextAreaWithButton";
import { useHistory, useRouteMatch } from "react-router-dom";

const ProjectUpdates = () => {
  const [newComment, updateNewComment] = useState("");
  const history = useHistory();
  const route = useRouteMatch();

  return (
    <div>
      <div className={styles.updates__container}>
        <Button
          small
          text="Add Update"
          action={() => history.push(`${route.url}/new`)}
          styles={{
            color: "#0251bc",
            background: "#e2f6ff",
            padding: "13px 25px",
            marginLeft: "15px",
          }}
        />
        {/* <button
          className={styles.updates__button}
          onClick={() => history.push(`${route.url}/new`)}
        >
          Add Update
        </button> */}
      </div>

      <div className={styles.main__container}>
        <section className={styles.left__section}>
          <Comment
            {...{
              name: "Jody Roberts",
              text:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
              time: "Yesterday, 11:59pm",
              image: pic2,
              altVersion: true,
            }}
          />
          <Comment
            {...{
              name: "Jody Roberts",
              text:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
              time: "Yesterday, 11:59pm",
              image: pic2,
              altVersion: true,
            }}
          />
          <Comment
            {...{
              name: "Jody Roberts",
              text:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
              time: "Yesterday, 11:59pm",
              image: pic2,
              altVersion: true,
            }}
          />
        </section>
        <section className={styles.right__section}>
          <div className={styles.addComment__container}>
            <p className={styles.addComment__heading}>Add a comment</p>
            <TextAreaWithButton
              name="commentBox"
              value={newComment}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                updateNewComment(e.target.value)
              }
              buttonText="ADD COMMENT"
            />
          </div>
          <div className={styles.comment__container}>
            <p className={styles.comment__heading}>Comments</p>

            <div>
              <Comment
                {...{
                  name: "Jody Roberts",
                  text:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
                  time: "11:59pm",
                  image: pic2,
                  altVersion: true,
                }}
              />
              <Comment
                {...{
                  name: "Jody Roberts",
                  text:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
                  time: "11:59pm",
                  image: pic2,
                  altVersion: true,
                }}
              />
              <Comment
                {...{
                  name: "Jody Roberts",
                  text:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
                  time: "11:59pm",
                  image: pic2,
                  altVersion: true,
                }}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProjectUpdates;
