import React, { ElementType, FunctionComponent } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import NewProject from "./NewProject";
import ProjectAll from "./ProjectsAll";
import ProjectDetail from "./Project";
import NewDispute from "./NewDispute";

interface RouteElement {
  path: string;
  exact: boolean;
  Child: ElementType;
}

const routes: RouteElement[] = [
  {
    path: "",
    exact: true,
    Child: ProjectAll,
  },
  {
    path: "new",
    exact: true,
    Child: NewProject,
  },
  {
    path: ":id/newdispute",
    exact: true,
    Child: NewDispute,
  },
  {
    path: ":id",
    exact: false,
    Child: ProjectDetail,
  },
];

const Projects: FunctionComponent = () => {
  const { path: pathName } = useRouteMatch();

  return (
    <Switch>
      {routes.map(({ path, exact, Child }, index) => (
        <Route
          exact={exact}
          path={`${pathName}/${path}`}
          key={`${pathName}/${path}/${index}`}
        >
          <Child />
        </Route>
      ))}
    </Switch>
  );
};

export default Projects;
