import React, { useState } from "react";
import styles from "./index.module.scss";
import Textarea from "Components/Textarea";
import { ReactComponent as PaperClip } from "Assets/svg/paperClip.svg";

const UpdateRequirements = () => {
  const [textField, updateTextField] = useState("");

  return (
    <div className={styles.container}>
      <Textarea
        value={textField}
        name="textField"
        label="Enter project requirements"
        action={(e) => updateTextField(e.target.value)}
      />

      <div className={styles.buttonContainer}>
        <button className={styles.submitButton}>Update Requirements</button>
      </div>
    </div>
  );
};

export default UpdateRequirements;
