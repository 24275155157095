import React, { FunctionComponent } from "react";
import Navbar from "Components/Navbar";
import OtherFooter from "Components/OtherFooter";
import WorkovaAdvantages from "Components/WorkovaAdvantages";
import Styles from "./index.module.scss";
import { ReactComponent as CheckMark } from "Assets/svg/checkMark.svg";

const PricingCard: FunctionComponent<{
  cardDetails: string[];
  type: string;
  heading: string;
  buttonText: string;
  showAmount: boolean;
}> = ({ cardDetails, type, heading, buttonText, showAmount }) => {
  return (
    <div className={Styles.pricingCard}>
      <h3 className={Styles.pricingCard__type}>{type}</h3>
      {showAmount ? null : (
        <p className={Styles.pricingCard__title}>{heading}</p>
      )}
      {showAmount ? (
        <p className={Styles.pricingCard__amountTitle}>
          <span>$</span>
          <span>{heading}</span>
          <span>/Month</span>
        </p>
      ) : null}
      <ul className={Styles.pricingCard__list}>
        {cardDetails.map((detail, index) => (
          <li key={index}>
            <div>
              <CheckMark />
            </div>
            <span>{detail}</span>
          </li>
        ))}
      </ul>

      <button className={Styles.pricingCard__button}>{buttonText}</button>
    </div>
  );
};

const Pricing = () => {
  return (
    <div>
      <Navbar />
      <div className={Styles.heroSection}>
        <h3>
          Workova is the best approach to solving your talent shortage problems
        </h3>
        <p>Our pricing is simple</p>
      </div>
      <div className={Styles.pricingSection}>
        <div className={Styles.pricingContainer}>
          <PricingCard
            cardDetails={[
              "Search and view talents",
              "Post your projects for free",
              "Subscribe to workspaces for your projects",
              "Get project updates",
            ]}
            type="Basic"
            heading="Free"
            buttonText="Get Basic"
            showAmount={false}
          />
          <PricingCard
            cardDetails={[
              "Everything in Basic",
              "Live videos and interview",
              "Schedule video calls for team meetings",
            ]}
            type="Recruiter"
            heading="500"
            buttonText="Get Recruiter"
            showAmount
          />
          <PricingCard
            cardDetails={[
              "Everything in Recruiter",
              "Dedicated recruitment manager",
              "Dedicated Project Manager",
              "Dedicated workspace & tools",
            ]}
            type="Enterprise"
            heading="Email"
            buttonText="Contact Us"
            showAmount={false}
          />
        </div>
      </div>
      <WorkovaAdvantages />
      <OtherFooter />
    </div>
  );
};

export default Pricing;
