import React from "react";
import ProfileCard from "Components/ProfileCard";
import prof1 from "Assets/images/Ellipse 18.png";
import prof2 from "Assets/images/men 3 1.png";
import prof3 from "Assets/images/Ellipse 17.png";
import prof4 from "Assets/images/Ellipse 16.png";
import arrowLeft from "Assets/images/projectcard/arrow.png";
import arrowRight from "Assets/images/projectcard/arrow1.png";
import styles from "./ProfileRecommendation.module.scss";

const ProfileRecommendation = () => {
  return (
    <div className={styles.recommend}>
      <div className={styles.info}>
        <p className={styles.rec}>Recommended Talents for this Project</p>
        <div className={styles.navarrow}>
          <div className={styles.arrow}>
            <img src={arrowLeft} alt="arrows" />
          </div>
          <div className={styles.arrow}>
            <img src={arrowRight} alt="arrows" />
          </div>
        </div>
      </div>
      <div className={styles.card}>
        <ProfileCard
          image={prof1}
          title="Python Developer"
          email="jody.booms@gmail.com"
          name="Jody Booms"
          location="Madagascar"
          rating="3.5"
        />
        <ProfileCard
          image={prof2}
          title="Python Developer"
          email="jody.booms@gmail.com"
          name="Jody Booms"
          location="Madagascar"
          rating="3.5"
        />
        <ProfileCard
          image={prof3}
          title="Python Developer"
          email="jody.booms@gmail.com"
          name="Jody Booms"
          location="Madagascar"
          rating="3.5"
        />
        <ProfileCard
          image={prof4}
          title="Python Developer"
          email="jody.booms@gmail.com"
          name="Jody Booms"
          location="Madagascar"
          rating="3.5"
        />
      </div>
    </div>
  );
};

export default ProfileRecommendation;
