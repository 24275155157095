import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "Pages/Public/Home";
import AuthRoutes from "Pages/Auth";
import Dashboard from "Pages/Dashboard";
import AboutUs from "Pages/Public/AboutUs";
import Pricing from "Pages/Public/Pricing";
import ProtectedRoute from "Components/ProtectedRoute";
import Projects from "Pages/Public/Project";
import Workspace from "Pages/Public/Workspaces";
import Talent from "Pages/Public/Talent";
import TalentSearch from "Pages/Public/TalentSearch";

const routes = [
  {
    path: "/",
    exact: true,
    Child: Home,
    protect: false,
  },
  {
    path: "/about",
    exact: true,
    Child: AboutUs,
    protect: false,
  },
  {
    path: "/talent/search",
    exact: true,
    Child: TalentSearch,
    protect: true,
  },
  {
    path: "/talent/:id",
    exact: false,
    Child: Talent,
    protect: true,
  },
  {
    path: "/pricing",
    exact: true,
    Child: Pricing,
    protect: false,
  },
  {
    path: "/auth",
    exact: false,
    Child: AuthRoutes,
    protect: false,
  },
  {
    path: "/dashboard",
    exact: false,
    Child: Dashboard,
    protect: true,
  },
  {
    path: "/projects",
    exact: false,
    Child: Projects,
    protect: false,
  },
  {
    path: "/workspaces",
    exact: false,
    Child: Workspace,
    protect: false,
  },
];
export default function Routes() {
  return (
    <Router>
      <Switch>
        {routes.map(({ path, exact, Child, protect }, index) =>
          protect ? (
            <ProtectedRoute
              Component={Child}
              exact={exact}
              path={path}
              key={index}
            />
          ) : (
            <Route exact={exact} path={path} key={index}>
              <Child />
            </Route>
          )
        )}
      </Switch>
    </Router>
  );
}
