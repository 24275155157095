import React from 'react';
import styles from "./styles.module.scss";
import Button from 'Components/Button';
import Notifs, { notifItem } from "../atom/Notifs";
import NotifDetail from "../atom/NotifDetail";
import avatar from "Assets/images/Ellipse 17.png";
import filterIcon from "Assets/svg/filter__icon.svg";

const notifiArray: notifItem[] = [
  {
    avatar: avatar,
    sender: "Jay The Recruiter",
    time: "2 days",
    message: "Hi, can we have a chat by 12noon WAT...",
  },
  {
    avatar: avatar,
    sender: "Jay The Recruiter",
    time: "2 days",
    message: "Hi, can we have a chat by 12noon WAT...",
  },
  {
    vidCallSchedule: true,
    time: "2 days",
    message: "Hi, can we have a chat by 12noon WAT...",
  },
  {
    avatar: avatar,
    sender: "Jay The Recruiter",
    time: "2 days",
    message: "Hi, can we have a chat by 12noon WAT...",
  },
  {
    avatar: avatar,
    sender: "Jay The Recruiter",
    time: "2 days",
    message: "Hi, can we have a chat by 12noon WAT...",
  },
  {
    vidCallSchedule: true,
    time: "2 days",
    message: "Hi, can we have a chat by 12noon WAT...",
  },
];

const Notifications = () => {
  return (
    <div className={styles.notification}>
      <div className={styles.left}>
        <div className={styles.top}>
          <Button
            small
            sideIcon={filterIcon}
            invert
            text="All Video Calls"
            action={() => null}
            styles={{
              border: "1px solid #c9d7eb",
              padding: "9px 0",
              width: "32%",
            }}
          />
          <Button
            small
            text="Add New"
            action={() => null}
            styles={{
              border: "1px solid #c9d7eb",
              padding: "9px 0",
              width: "25%",
            }}
          />
        </div>
        <div className={styles.container}>
          {notifiArray.map((notif1, index) => (
            <Notifs {...notif1} key={index} />
          ))}
        </div>
      </div>
      <div className={styles.right}>
        <NotifDetail
          avatar={avatar}
          sender="Jenifer Booms"
          date="Nov 29, 2020"
          role="Recruiter"
          location="Germany"
          email="aupairs@helper.com"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        />
      </div>
    </div>
  );
}

export default Notifications;
