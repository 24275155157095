import { Dispatch } from "redux";
import Axios from "axios";
import actionCreator from "Utils/actionCreator";
import {
  GET_ALL_PROJECTS,
  GET_ALL_PROJECTS_SUCCESS,
  GET_ALL_PROJECTS_FAILURE,
  GET_ONE_PROJECT_DETAILS,
  GET_ONE_PROJECT_DETAILS_SUCCESS,
  GET_ONE_PROJECT_DETAILS_FAILURE,
} from "../constants/project.contants";

const getAllProjects = (
  action?: () => void,
  errorAction?: () => void
) => async (dispatch: Dispatch) => {
  dispatch(actionCreator(GET_ALL_PROJECTS));

  try {
    const { data: response } = await Axios.get(`/projects?populate=user`);

    if (response.status === "success") {
      const { status, ...rest } = response;
      const { data: projects } = rest;

      dispatch(actionCreator(GET_ALL_PROJECTS_SUCCESS, projects));
    }
    action && action();
  } catch (error) {
    console.log(error);
    errorAction && errorAction();
    dispatch(actionCreator(GET_ALL_PROJECTS_FAILURE));
  }
};

const getProjectDetails = (
  projectId: string,
  action?: () => void,
  errorAction?: () => void
  ) => async (dispatch: Dispatch) => {
  dispatch(actionCreator(GET_ONE_PROJECT_DETAILS));

  try {
    const { data: response } = await Axios.get(`/projects/${projectId}`);

    if (response.status === "success") {
      const { status, ...rest } = response;
      const { data: project } = rest;

      dispatch(actionCreator(GET_ONE_PROJECT_DETAILS_SUCCESS, project));
      action && action();
    }
  } catch (error) {
    errorAction && errorAction();
    dispatch(actionCreator(GET_ONE_PROJECT_DETAILS_FAILURE, error))
  }
};

export { getAllProjects, getProjectDetails };
