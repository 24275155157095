import React, { FunctionComponent } from "react";
import Styles from "./index.module.scss";

const PublicHero: FunctionComponent<{ img: string; title: string }> = ({
  img,
  title,
}) => {
  return (
    <div className={Styles.heroContainer}>
      <img src={img} alt="hero" />
      <div className={Styles.heroOverlay}>
        <h2 className={Styles.heroTitle}>{title}</h2>
      </div>
    </div>
  );
};

export default PublicHero;
