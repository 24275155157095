import React from "react";
import style from "./projectCard.module.scss";
import loc from "Assets/images/flag.svg";
import walle from "Assets/images/walle.svg";

export interface CardItem {
  image: string;
  title: string;
  date?: string;
  author: string;
  name: string;
  location: string;
  action?: () => void;
  styles?: Object;
  straight?: boolean;
  wall?: boolean;
}

const ProjectCard = ({
  image,
  title,
  author,
  name,
  location,
  action,
  styles,
  straight,
  wall,
  date,
}: CardItem) => {
  return (
    <div className={style.card} onClick={action} style={styles}>
      <img
        className={`${straight ? style.straight : ""}`}
        src={image}
        alt="project"
      />
      {wall ? <img className={style.top} src={walle} alt="docket" /> : null}
      {date ? <p className={style.date}>{date}</p> : null}
      <p className={style.text}>{title}</p>
      <div className={style.bottom}>
        <div className={style.side}>
          <img className={style.pic} src={author} alt="" />
          <div>
            <p className={style.name}>{name}</p>
            <p className={style.location}>
              <img className={style.locate} src={loc} alt="here" />
              {location}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
