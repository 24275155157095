import React from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "./style.module.scss";
import logo from "Assets/images/logo.svg";
import Button from "Components/Button";

export default function Activation() {
  const history = useHistory();
  return (
    <div className={styles.Activation}>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="logo" />
      </Link>

      <h3 className={styles.welcome}>Welcome to Workova</h3>
      <p className={styles.sub}>
        Your account has been created successfully. To continue, please check
        your email and activate your account
      </p>

      <form className={styles.form}>
        <Button
          full
          styles={{
            marginTop: "20%",
            marginBottom: "10%",
          }}
          text="I have Activated My Account"
          action={() => history.push("/")}
        />
      </form>
      <p className={styles.footer}>
        <Link to="#">Resend Activation link</Link>
      </p>
    </div>
  );
}
