import React from "react";
import Navbar from "Components/Navbar";
import OtherFooter from "Components/OtherFooter";
import TalentCard from "Components/TalentCard";
import TalentSearchBar from "Components/TalentSearchBar";

import TalentCardPic from "Assets/images/TalentCardPic.png";
import styles from "./index.module.scss";

const TalentSearch = () => {
  return (
    <div>
      <Navbar />
      <main className={styles.mainSection}>
        <div>
          <h3 className={styles.mainHeading}>Find Talents</h3>
        </div>
        <div>
          <TalentSearchBar />
        </div>
        <div className={styles.cardContainer}>
          <TalentCard
            image={TalentCardPic}
            name="Pablo Escobar"
            title="Python Developer"
            country="Mexico"
            badge
          />
          <TalentCard
            image={TalentCardPic}
            name="Pablo Escobar"
            title="Python Developer"
            country="Mexico"
            badge
          />
          <TalentCard
            image={TalentCardPic}
            name="Pablo Escobar"
            title="Python Developer"
            country="Mexico"
            badge
          />
          <TalentCard
            image={TalentCardPic}
            name="Pablo Escobar"
            title="Python Developer"
            country="Mexico"
            badge
          />
          <TalentCard
            image={TalentCardPic}
            name="Pablo Escobar"
            title="Python Developer"
            country="Mexico"
            badge
          />
          <TalentCard
            image={TalentCardPic}
            name="Pablo Escobar"
            title="Python Developer"
            country="Mexico"
            badge
          />
          <TalentCard
            image={TalentCardPic}
            name="Pablo Escobar"
            title="Python Developer"
            country="Mexico"
            badge
          />
          <TalentCard
            image={TalentCardPic}
            name="Pablo Escobar"
            title="Python Developer"
            country="Mexico"
            badge
          />
        </div>
      </main>
      <OtherFooter />
    </div>
  );
};

export default TalentSearch;
