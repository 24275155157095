import React from "react";
import Navbar from "Components/Navbar";
import PublicHero from "Components/PublicHero";

import heroImg from "Assets/images/base_hero.png";
import { ReactComponent as RightArrow } from "Assets/svg/right_arrow_button.svg";
import aboutImgOne from "Assets/images/aboutImg1.png";
import Styles from "./index.module.scss";
import OtherFooter from "Components/OtherFooter";
import WorkovaAdvantages from "Components/WorkovaAdvantages";

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <PublicHero
        img={heroImg}
        title="We connect highly skilled talents with businesses"
      />
      <main>
        <section className={Styles.rowSectionOne}>
          <div className={Styles.sectionOne__img}>
            <img
              src={aboutImgOne}
              alt="programmer smiling and working on a laptop"
            />
          </div>
          <div className={Styles.sectionOne__content}>
            <div className={Styles.content__tabs}>
              <span></span>
              <span></span>
            </div>
            <p className={Styles.content__paragraph}>
              We connect highly skilled talents with businesses to enable them
              to get high paying jobs, share their workload and earn a steady
              income in a productive workspace with the right work tools
            </p>

            <div className={Styles.content__button}>
              <button>
                <span>Hire A Freelancer</span>
                <RightArrow />
              </button>
              <button>Pricing</button>
            </div>
          </div>
        </section>

        <WorkovaAdvantages />
      </main>
      <OtherFooter />
    </div>
  );
};

export default AboutUs;
