import React, { useState, useRef, useEffect } from "react";
import logo from "Assets/images/logo.svg";
import logout from "Assets/images/export.svg";
import dash from "Assets/images/dash.svg";
import pro1 from "Assets/images/Ellipse 17.png";
import styles from "./navbar.module.scss";
import { NavLink, Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

interface Props {}

const Navbar = (props: Props) => {
  const [open, setopen] = useState(false);
  const history = useHistory();
  const user = useSelector(({ userReducer }: any) => userReducer.user);
  let dropRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const clickOutside = (e: any) => {
      if (dropRef && dropRef.current && !dropRef.current.contains(e.target)) {
        setopen(false);
      };
    };
    // Add an event to close dropdown on clicking outside
    document.addEventListener("mousedown", clickOutside);
    // clean up after the function has ran
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [dropRef]);


  return (
    <nav className={styles.nav}>
      <div className={styles.left}>
        <NavLink className={styles.logo} to="/">
          <img src={logo} alt="logo" />
        </NavLink>
        <ul>
          <li>
            <NavLink to="/about" activeClassName={styles.active} exact>
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/projects" activeClassName={styles.active}>
              Projects
            </NavLink>
          </li>
          <li>
            <NavLink to="/workspaces" activeClassName={styles.active}>
              Workspaces
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/courses" activeClassName={styles.active} exact>
              Courses
            </NavLink>
          </li>
          <li>
            <NavLink to="/events" activeClassName={styles.active} exact>
              Events
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/pricing" activeClassName={styles.active} exact>
              Pricing
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/verified-freelancers"
              activeClassName={styles.active}
              exact
            >
              Verified Freelancers
            </NavLink>
          </li> */}
        </ul>
      </div>
      {user.email ? (
        <button
          ref={dropRef}
          className={styles.auth}
          onClick={() => setopen((prev) => !prev)}
        >
          <div className={styles.avatar}>
            <img src={pro1} alt="pics" />
          </div>
          <p className={styles.name}>{`${user.firstName} ${user.lastName}`}</p>

          {open && (
            <div className={styles.items}>
              <button
                className={styles.item}
                onClick={() => {
                  history.push("/dashboard/main");
                }}
              >
                <img src={dash} alt="pics" />
                Dashboard
              </button>
              <button
                className={styles.item}
                onClick={() => {
                  localStorage.clear();
                  history.push("/");
                  history.go(0);
                }}
              >
                <img src={logout} alt="pics" />
                Logout
              </button>
            </div>
          )}
        </button>
      ) : (
        <div className={styles.right}>
          <Link to="auth/signin">Sign In</Link>
          <div className={styles.seperator} />
          <Link className={styles.btn} to="/auth/hire-talent">
            Post A Job
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
