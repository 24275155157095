import Axios from "axios";


export const initializeSession = () => async (dispatch: any) => {
  try {
    const { data: response } = await Axios.get("/initialize");
    if (response.data["x-tag"]) {
      Axios.defaults.headers.common["x-tag"] = response.data["x-tag"];
      sessionStorage.setItem("xtag", response.data["x-tag"]);
    }
  } catch (error) {
    console.log(error);
  }
};
