import React, { FunctionComponent } from "react";
import Styles from "./index.module.scss";
import { ReactComponent as LocationIcon } from "Assets/svg/locationIcon.svg";
import { IProjectCard } from "../../models";

const ProjectCard: FunctionComponent<IProjectCard> = ({
  heading,
  description,
  avatar,
  ownerLocation,
  ownerName,
}) => {
  return (
    <div className={Styles.container}>
      <h4 className={Styles.heading}>{heading} </h4>
      <p className={Styles.description}>{description}</p>
      <div className={Styles.footer}>
        <div className={Styles.footer__image}>
          <img src={avatar} alt="user avatar" />
        </div>
        <div className={Styles.footer__textInfo}>
          <p className={Styles.card__name}>{ownerName}</p>
          <p className={Styles.card__location}>
            <span className={Styles.card__icon}>
              <LocationIcon />
            </span>
            <span className={Styles.card__locationTitle}>{ownerLocation}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
