import React, { useState, useRef, useEffect } from "react";
import Styles from "./index.module.scss";
import { Field } from "formik";
import Button from 'Components/Button';

interface FileInputProps {
  label: string;
  name: string;
  value: any;
  action: (...e: any) => void;
}
const CustomFileInput = ({ label, name, action, value }: FileInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState("No file chosen");

  function openFileInput() {
    const inputNode = inputRef.current;
    if (inputNode) {
      inputNode.click();
      inputNode.focus();
    }
  }

  function handleFileSelect() {
    const inputNode = inputRef.current;
    if (inputNode) {
      if (inputNode.files) {
        const file = inputNode.files[0];
        setFileName(file.name);
        action(name, file);
      }
    }
  }

  return (
    <div className={Styles.container}>
      <label className={Styles.label} htmlFor="banner">
        {label}
        <div className={Styles.inputContainer}>
          <Field
            fileName="stack.jpg"
            onChange={handleFileSelect}
            innerRef={inputRef}
            className={Styles.fileInput}
            type="file"
            id="banner"
            name="avatar"
            accept="image/png, image/jpeg"
          ></Field>
          <span className={Styles.fileCustom}>{fileName}</span>
          {/* <button onClick={openFileInput}>Choose Image</button> */}
          <Button
              small
              text="Choose Image"
              action={openFileInput}
              styles={{
                color: "#0251bc",
                background: "#e2f6ff",
                padding: "13px 25px",
                marginLeft: "15px",
              }}
            />
        </div>
      </label>
    </div>
  );
};

export default CustomFileInput;
