import React, { ReactType } from "react";
import style from "./routetab.module.scss";
import { NavLink } from "react-router-dom";
import { Switch, Route, useRouteMatch } from "react-router-dom";

interface INavLink {
  path: string;
  title: string;
  exact: boolean;
}
interface RouteType {
  path: string;
  title: string;
  component: ReactType;
}
interface Props {
  tabs: INavLink[];
  switchRoutes: RouteType[];
}

const RouteTab = ({ tabs, switchRoutes }: Props) => {
  const { path: pathName, url } = useRouteMatch();
  return (
    <div className={style.routetab}>
      <div className={style.nav}>
        {tabs.map((tab, i) => (
          <NavLink
            activeClassName={style.active}
            exact={tab.exact}
            to={`${url}${tab.path}`}
            key={i}
          >
            {tab.title}
          </NavLink>
        ))}
      </div>
      <div className={style.content}>
        <Switch>
          {switchRoutes.map(({ path, component: Child }, index) => (
            <Route
              exact={true}
              path={`${pathName}${path}`}
              key={`${pathName}/${path}/${index}`}
            >
              <Child />
            </Route>
          ))}
        </Switch>
      </div>
    </div>
  );
};

export default RouteTab;
