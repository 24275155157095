import { FETCH_SKILLS_REQUEST, FETCH_SKILLS_SUCCESS, FETCH_SKILLS_FAILURE } from "Store/constants/skill.constants";

interface SkillState {
  skills: Object;
  loading: boolean;
  error: Object | null;
}

const initialState: SkillState = {
  skills: {},
  loading: false,
  error: null,
}

export default function skillReducer(state = initialState, action: any): SkillState {
  switch (action.type) {
    case FETCH_SKILLS_REQUEST:
      return { ...state, loading: true };
    case FETCH_SKILLS_SUCCESS:
      return { ...state, loading: false, skills: action.payload }
    case FETCH_SKILLS_FAILURE:
      return { ...state, loading: false, skills: action.payload }
    default:
      return state;
  }
}