import actionCreator from "Utils/actionCreator"
import { FETCH_SKILLS_REQUEST, FETCH_SKILLS_SUCCESS, FETCH_SKILLS_FAILURE } from "Store/constants/skill.constants"
import Axios from "axios";

export const fetchAllSkills = (
  action?: () => void,
  errorAction?: () => void,
) => async (dispatch: any) => {
  dispatch(actionCreator(FETCH_SKILLS_REQUEST));
  try {
    const { data: response } = await Axios.get(`/skills`);
    if (response.status === "success") {
      const { status, ...result } = response;

      dispatch(
          actionCreator(FETCH_SKILLS_SUCCESS, result)
      );
      action && action();
    }
  } catch (error) {
    console.log(error);
    errorAction && errorAction();
    dispatch(actionCreator(FETCH_SKILLS_FAILURE));
  }
}