import React, { ReactType } from "react";

import Styles from "./ProjectDetail/projectDetail.module.scss";
import RouteTab from "Components/RouteTab";
import ProjectDetailContent from "./ProjectDetail";
import ContractContent from "./Contract";
import RecruiterContractContent from "./RecruiterContract";
import AllDisputes from "../AllDisputes";
import DisputeDetails from "../DisputeDetails";
import ProjectUpdates from "./ProjectUpdates";

interface INavLink {
  path: string;
  title: string;
  exact: boolean;
}
interface RouteType {
  path: string;
  title: string;
  component: ReactType;
}

const routes: INavLink[] = [
  { title: "Project Details", path: "", exact: true },
  // { title: "Project Team", path: "/team", exact: true },
  { title: "Project Disputes", path: "/disputes", exact: false },
  { title: "Project Updates", path: "/updates", exact: false },
  { title: "Contract", path: "/contract", exact: false },
  { title: "Recruiter Contract", path: "/contract-recruiter", exact: false },
];

const switchRoutes: RouteType[] = [
  { title: "Project Details", path: "", component: ProjectDetailContent },
  { title: "Project Team", path: "/team", component: () => <h1>Teams</h1> },
  { title: "Disputes", path: "/disputes", component: AllDisputes },
  { title: "Disputes", path: "/disputes/:id", component: DisputeDetails },
  {
    title: "Updates",
    path: "/updates",
    component: ProjectUpdates,
  },
  {
    title: "Contract",
    path: "/contract",
    component: ContractContent,
  },
  {
    title: "Recruiter Contract",
    path: "/contract-recruiter",
    component: RecruiterContractContent,
  },
];

const ProjectDetail = () => {
  return (
    <div className={Styles.main}>
      <RouteTab tabs={routes} switchRoutes={switchRoutes} />
    </div>
  );
};

export default ProjectDetail;
