import React, { useState, FunctionComponent } from "react";
import DatePicker from "react-datepicker";

import Styles from "./index.module.scss";
import { ReactComponent as DownArrow } from "Assets/svg/down__arrow.svg";

interface ICustomInput {
  value?: any;
  onClick?: any;
}

interface Props {
  name: string;
  label: string;
  value: string;
  action: (...e: any) => void;
}

const CustomDatePicker: FunctionComponent<Props> = ({ name, label, action, value }) => {
  const [startDate, updateStartDate] = useState<Date | null>(new Date());

  const CustomInput = ({ onClick, value }: ICustomInput) => {
    return (
      <button onClick={() => onClick()} className={Styles.datepicker__button}>
        <span>{value}</span>
        <DownArrow />
      </button>
    );
  };

  return (
    <div className={Styles.container}>
      <p className={Styles.label}>{label}</p>
      <DatePicker
        selected={startDate}
        value={value}
        onChange={(date) => {updateStartDate(date); action(name, date)}}
        customInput={<CustomInput />}
        className={Styles.datepicker__container}
      />
    </div>
  );
};

export default CustomDatePicker;
