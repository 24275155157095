/* tslint:disable */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import Button from "Components/Button";
import styles from "./style.module.scss";

export const Wizard = ({ children, initialValues, onSubmit }: any) => {
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values: any) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (values: any) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (values: any, bag: any) => {
    // @ts-ignore
    if (step.props.onSubmit) {
      // @ts-ignore
      await step.props.onSubmit(values, bag);
    }
    if (isLastStep) {
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      next(values);
    }
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      // @ts-ignore
      validationSchema={step.props.validationSchema}
    >
      {formik => {
        return (
          <Form className={styles.form}>
            {step}
            <div>
              {stepNumber > 0 && (
                <Button
                  full
                  styles={{
                    padding: "15px 50px",
                    marginTop: "20px",
                    marginBottom: "3%",
                  }}
                  text="Back"
                  action={() => previous(formik.values)}
                />
              )}
              <Button
                full
                styles={{
                  padding: "15px 50px",
                  marginTop: "20px",
                  marginBottom: "3%",
                }}
                text={isLastStep ? "Submit" : "Proceed"}
                action={() => {}}
              />
            </div>
          </Form>
        );}
      }
    </Formik>
  );
};

export const WizardStep = ({ children }: any) => children;
