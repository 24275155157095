import React from "react";
import styles from "./checkbox.module.scss";
import { Field } from "formik";

interface Props {
  label: string;
  onchange?: () => void;
  value?: boolean | false;
}

const Checkbox = ({ label, value, onchange }: Props) => {
  return (
    <button onClick={onchange} type="button" className={styles.checkbox}>
      <div className={`${styles.check} ${value ? styles.checked : ""}`}></div>
      {label}
    </button>
  );
};

interface checkboxProps {
  name: string;
  value: string;
  label: string;
}

export const CheckBox = ({name, value, label}: checkboxProps) => (
  <div className={styles.container}>
    <Field id={value} type="checkbox" name={name} value={value} />
    <label htmlFor={value}>
      {label}
    </label>
  </div>
);

export default Checkbox;
