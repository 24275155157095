import React, { useState } from "react";
import style from "./input.module.scss";
import NumberFormat from "react-number-format";

interface Props {
  value: string;
  name: string;
  label?: string;
  placeholder: string;
  action: (...e: any) => void;
  password?: boolean;
  margin?: boolean;
  number?: boolean;
}

const Input = ({
  name,
  value,
  action,
  password,
  placeholder,
  label,
  margin,
  number,
}: Props) => {
  const [readOnly, setReadOnly] = useState(true);
  return (
    <div className={`${style.input} ${margin ? style.margin : ""}`}>
      {label && <label>{label}</label>}
      {number ? (
        <NumberFormat
          value={value}
          thousandSeparator={true}
          prefix={"$"}
          onValueChange={({ value }) => action(name, value)}
          placeholder={placeholder}
          name={name}
          renderText={(newValue) => <input value={newValue} />}
        />
      ) : (
        <input
          onMouseDown={() => setReadOnly(false)}
          onFocus={() => setReadOnly(false)}
          name={name}
          type={password ? "password" : number ? "number" : "text"}
          onChange={action}
          value={value}
          placeholder={placeholder}
          readOnly={readOnly}
        />
      )}
    </div>
  );
};

export default Input;
