import React, { useState, useEffect } from "react";
import styles from "./allworkspaces.module.scss";
import WorkspaceCard, { CardItem } from "Components/WorkspaceCard";
import img1 from "Assets/images/workspace.png";
import filterIcon from "Assets/svg/filter__icon.svg";
// import walletEmpty from "Assets/svg/wallet-empty.svg";
import Button from "Components/Button";
import arrowLeft from "Assets/images/projectcard/arrow.png";
import arrowRight from "Assets/images/projectcard/arrow1.png";
import { useHistory } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { fetchUserProjects } from "Store/actions/project.action";

interface Search {
  text: string;
}

const AllWorkspaces = () => {
  const [state, setState] = useState<Search>({ text: "" });
  const history = useHistory();

  const theAction = () => history.push("/dashboard/workspaces/1");

  const workspaceArray: CardItem[] = [
    {
      image: img1,
      title: "API Project Workspace",
      status: "Opens",
      location: "Brazil",
      time: "9am - 10pm",
      action: theAction,
    },
    {
      image: img1,
      title: "API Project Workspace",
      status: "Opens",
      location: "Brazil",
      time: "9am - 10pm",
      action: theAction,
    },
    {
      image: img1,
      title: "API Project Workspace",
      status: "Opens",
      location: "Brazil",
      time: "9am - 10pm",
      action: theAction,
    },
    {
      image: img1,
      title: "API Project Workspace",
      status: "Opens",
      location: "Brazil",
      time: "9am - 10pm",
      action: theAction,
    },
    {
      image: img1,
      title: "API Project Workspace",
      status: "Opens",
      location: "Brazil",
      time: "9am - 10pm",
      action: theAction,
    },
    {
      image: img1,
      title: "API Project Workspace",
      status: "Opens",
      location: "Brazil",
      time: "9am - 10pm",
      action: theAction,
    },
  ];

  // const dispatch = useDispatch();
  // const { user, projects } = useSelector(
  //   ({ userReducer, projectReducer }: any) => ({
  //     user: userReducer.user,
  //     projects: projectReducer.projects,
  //   })
  // );
  // const networkOnline = useSelector(
  //   ({ networkReducer }: any) => networkReducer.networkOnline
  // );

  // useEffect(() => {
  //   if (networkOnline && user.account)
  //     dispatch(fetchUserProjects(user._id));
  // }, [networkOnline, user.account, dispatch]);

  const setValue = (e: any) =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

  // return projects.data && projects.data.length ?
  return (
    <>
      <div className={styles.allworkspace}>
        <div className={styles.container}>
          <div className={styles.top}>
            <Button
              small
              sideIcon={filterIcon}
              invert
              text="Filter by location"
              action={() => null}
              styles={{
                border: "1px solid #c9d7eb",
                padding: "9px 10px 9px 0",
                width: "17%",
              }}
            />
          </div>
          <div className={styles.cards}>
            {workspaceArray.map((workspace1, index) => (
              <WorkspaceCard {...workspace1} key={index} />
            ))}
          </div>
          <div className={styles.paginate}>
            <div className={styles.numbers}>
              <p>1</p>
              <p>2</p>
              <p>3</p>
              <p>4</p>
              <p>5</p>
              <p>...</p>
            </div>
            <div className={styles.navarrow}>
              <div className={styles.arrow}>
                <img src={arrowLeft} alt="arrows" />
              </div>
              <div className={styles.arrow}>
                <img src={arrowRight} alt="arrows" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  // : networkOnline ? (
  //   <div className={styles.empty}>
  //     <img src={walletEmpty} alt="empty project" />
  //     <p>
  //       Your projects will be displayed here. You have not created a project
  //       yet. Please add a new project
  //     </p>
  //     <footer>
  //       <Button
  //         medium
  //         styles={{
  //           color: "#0251BC",
  //           backgroundColor: "transparent",
  //           border: "1px solid #0251BC",
  //           marginRight: "12px",
  //         }}
  //         text="Find Freelancers"
  //         action={() => null}
  //       />
  //       <Button
  //         medium
  //         text="Add New Project"
  //         action={() => history.push("/dashboard/projects/new")}
  //       />
  //     </footer>
  //   </div>
  // ) : (
  //   <div className={styles.empty}>
  //     <img src={walletEmpty} alt="empty project" />
  //     <p>
  //       Your projects will be displayed here. None showed because of Network.
  //       Please connect to the Internet
  //     </p>
  //   </div>
  // );
};

export default AllWorkspaces;
