import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "Store/actions/allProjects.action";
import styles from "./allProject.module.scss";
import ProjectCard from "Components/ProjectCard";
import Navbar from "Components/Navbar";
import OtherFooter from "Components/OtherFooter";
import dots from "Assets/images/dots.png";
import pro1 from "Assets/images/projectcard/Rectangle 9 (4).png";
import authorPic from "Assets/images/Ellipse 17.png";

export default function Projects() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { loading, projects, error } = useSelector(
    ({ allProjectsReducer }: any) => ({
      loading: allProjectsReducer.loading,
      projects: allProjectsReducer.projects,
      error: allProjectsReducer.error,
    })
  );

  useEffect(() => {
    dispatch(getAllProjects());
  }, [dispatch]);

  const slicedProjects = (
    projects: Array<Object>,
    start: number,
    end: number
  ): any => {
    return projects.slice(start, end);
  };

  return (
    <main className={styles.allProject}>
      <Navbar />
      <section className={styles.top}>
        <div className={styles.heading}>
          <h1>Workova Projects</h1>
          <p>Find amazing projects on workova</p>
        </div>
        <div className={styles.topProjects}>
          {loading ? (
            <div>Loading...</div>
          ) : (
            slicedProjects(projects, 0, 3).map((project: any, index: number) => (
              <ProjectCard
                key={index}
                action={() => history.push(`/projects/${project._id}`)}
                image={project.images.length ? project.images[0] : pro1}
                title={project.title}
                price={`$${project.payoutAmount}`}
                author={authorPic}
                name={project.user ? `${project.user.firstName} ${project.user.lastName}` : "Jody Booms"}
                text={project.description}
                location="USA"
                straight
              />
            ))
          )}
        </div>
        <img className={styles.dots} src={dots} alt="pagination dots" />
      </section>
      <section className={styles.middle}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          slicedProjects(projects, 0, 6).map((project: any, index: number) => (
            <ProjectCard
              key={index}
              action={() => history.push(`/projects/${project._id}`)}
              image={project.images.length ? project.images[0] : pro1}
              title={project.title}
              price={`$${project.payoutAmount}`}
              author={authorPic}
              name={project.user ? `${project.user.firstName} ${project.user.lastName}` : "Jody Booms"}
              location="USA"
            />
          ))
        )}
      </section>
      <OtherFooter />
    </main>
  );
}
