import React, { useEffect } from "react";
import { Formik } from 'formik';
import Styles from "./index.module.scss";
import Input from "Components/Input";
import MultiValueSelect from "Components/MultiValueInput";
import Textarea from "Components/Textarea";
import Button from "Components/Button";
import CustomDatePicker from "Components/CustomDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { createUserProjects } from "Store/actions/project.action";
import { CheckBox } from "Components/Checkbox";
import * as Yup from 'yup';
import { fetchAllSkills } from "Store/actions/skill.action";
import FormError from "Components/FormError";
import { calculateWeeksFrom } from "Utils/helpers";
import CustomFileInput from "Components/CustomFileInput";

const TWO_MEGABYTES = 2097152;

function getSkillOptions(skills: any[] = []) {
  return skills.map(skill => ({
    value: skill._id,
    label: skill.name,
  }));
}
const newProjectSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Project title is required'),
  description: Yup.string()
    .min(2, 'Too Short!')
    .required('Project description is required'),
  payoutAmount: Yup.number()
    .required('Payout amount is required'),
  dueDate: Yup.date()
    .min(new Date(), 'Date selected cannot be earlier than today')
    .required('Due date is required'),
  skills: Yup.array().of(Yup.string())
    .min(1, 'Select at least one skill')
    .required('Select at least one skill').nullable(),
  projectImage: Yup.mixed()
    .required("Please upload a project image")
    .test(
      "fileSize",
      "Your image is too big :(",
      value => value && value.size <= TWO_MEGABYTES
    )
});

const NewProject = () => {
  const dispatch = useDispatch();
  const deliveryKeys: { [key: string]: string } = {
    hosting: "Hosting",
    design: "Design",
    frontend: "Frontend",
    backend: "Backend",
    sourceCode: "Source code",
    userGuide: "User guide",
  };
  interface FormProps {
    title: string;
    description: string;
    payoutAmount: string;
    dueDate: string;
    skills: any[];
    projectImage: File | null;
    additionalExpectations: string;
    deliveryExpectations: string[];
  }
  const initialValues: FormProps = {
    title: "",
    description: "",
    skills: [],
    payoutAmount: "",
    dueDate: "",
    projectImage: null,
    additionalExpectations: "",
    deliveryExpectations: ['hosting', 'design', 'frontend', 'backend', 'sourceCode', 'userGuide'],
  };

  const { user, createLoading, skills } = useSelector(
    ({ userReducer, projectReducer, skillReducer }: any) => ({
      user: userReducer.user,
      createLoading: projectReducer.createLoading,
      skills: skillReducer.skills,
    })
  );
  const networkOnline = useSelector(
    ({ networkReducer }: any) => networkReducer.networkOnline
  );

  useEffect(() => {
    if (networkOnline && user.account)
      dispatch(fetchAllSkills());
  }, [networkOnline, user.account, dispatch]);

  const createNewProject = (values: FormProps, onSuccess: () => void) => {
    dispatch(
      createUserProjects(user.account, {
        title: values.title,
        requiredSkills: values.skills.map(skill => skill.value).join(','),
        description: values.description,
        payoutAmount: Number(values.payoutAmount).toString(),
        deliveryExpectation: values.deliveryExpectations.join(','),
        dueDate: calculateWeeksFrom(values.dueDate).toString(),
        files: values.projectImage,
        additionalDeliveryExpectation: values.additionalExpectations.split('\n').join(','),
      }, onSuccess)
    );
  };

  const skillsOptions = getSkillOptions(skills.data && skills.data);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={newProjectSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        createNewProject(values, resetForm);
      }}
    >
      {
        ({ values, touched, errors, handleChange, setFieldValue, handleSubmit }) => (
          <div className={Styles.main}>
            <Input
              value={values.title}
              name="title"
              placeholder="Enter project topic"
              action={handleChange}
              label="Project Title"
            />
            <FormError error={errors.title} touched={touched.title} />
            <MultiValueSelect
              onChange={(value) => setFieldValue('skills', value)}
              value={values.skills}
              options={skillsOptions}
              placeholder="Start typing to see skills..."
            />
            <FormError error={errors.skills} touched={touched.skills} />
            <Textarea
              name="description"
              value={values.description}
              action={handleChange}
              label="Project Description"
            />
            <FormError error={errors.description} touched={touched.description} />
            <div className={Styles.row__container}>
              <div className={Styles.row__item}>
                <Input
                  value={values.payoutAmount}
                  name="payoutAmount"
                  placeholder="Enter payout"
                  action={setFieldValue}
                  label="Enter payout amount"
                  number={true}
                />
                <FormError error={errors.payoutAmount} touched={touched.payoutAmount} />
              </div>
              <div className={Styles.row__item}>
                <CustomDatePicker value={values.dueDate} name="dueDate" action={setFieldValue} label="Choose a due date for this project" />
                <FormError error={errors.dueDate} touched={touched.dueDate} />
              </div>
            </div>
            <div>
              <p className={Styles.deliveryHeading}>Delivery Expectations</p>
              <div className={Styles.delivery__container}>
                {Object.entries(deliveryKeys).map(
                  (deliveryItem) => {
                    const [value, label] = deliveryItem;
                    return (
                      <CheckBox key={value} name="deliveryExpectations" value={value} label={label}/>
                    )
                  }
                )}
              </div>
              <FormError error={errors.deliveryExpectations} touched={touched.deliveryExpectations} />
            </div>
            <div>
              <CustomFileInput value={values.projectImage} name="projectImage" action={setFieldValue} label="Project Image" />
              <FormError error={errors.projectImage} touched={touched.projectImage} />
            </div>
            <Textarea
              name="additionalExpectations"
              value={values.additionalExpectations}
              action={handleChange}
              label="Additional delivery expectations"
            />
            <div className={Styles.button__container}>
              <Button
                text="Submit And Deposit Link"
                action={handleSubmit}
                loading={createLoading}
              />
            </div>
          </div>
        )
      }
    </Formik>
  );
};

export default NewProject;
