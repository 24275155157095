import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './oneWorkspace.module.scss';
import Navbar from 'Components/Navbar';
import Button from 'Components/Button';
import WorkspaceCard, { CardItem } from "Components/WorkspaceCard";
import OtherFooter from 'Components/OtherFooter';
import dots from 'Assets/images/dots.png';
import pro1 from "Assets/images/projectcard/Rectangle 9 (4).png";
import loc from 'Assets/images/projectcard/Vector.png';
import price from 'Assets/images/suitcase.png';
import time from 'Assets/images/time.svg';
import love from 'Assets/images/love.svg';
import tools from 'Assets/images/tools.svg';
import space from 'Assets/images/spaces.svg';
import disclaim from 'Assets/images/disclaim.svg';
import img1 from "Assets/images/workspace.png";

export default function OneWorkspace() {
  const history = useHistory();

  const theAction = () => history.push("/workspaces/1");

  const uniqueStyle = {
    width: "24%"
  };

  const workspaceArray: CardItem[] = [
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction, styles: uniqueStyle},
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction, styles: uniqueStyle},
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction, styles: uniqueStyle},
    {image: img1, title: "API Project Workspace", status: "Opens", location: "Brazil", time: "9am - 10pm", action: theAction, styles: uniqueStyle},
  ]

  return (
    <main className={styles.oneworkspace}>
      <Navbar />
      <section className={styles.top}>
        <h2 className={styles.title}>API Project Workspace</h2>
        <div className={styles.details}>
          <div className={styles.left}>
            <img className={styles.wksp} src={pro1} alt="Workspace image" />
            <div className={styles.disclaimer}>
              <p className={styles.disclaim}>
                <img src={disclaim} alt="comment" />
                Disclaimer
              </p>
              <p className={styles.note}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
          <div className={styles.right}>
            <p className={styles.location}>
              <img src={loc} alt="location" />
              Sweden, Stockholm
            </p>
            <p className={styles.body}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <div className={styles.under}>
              <p className={styles.feat}>Features</p>
              <div className={styles.more}>
                <p className={styles.item}>
                  <img src={space} alt="spaces" />
                  Spaces
                </p>
                <p className={styles.item}>
                  <img src={love} alt="convenient" />
                  Convenience
                </p>
                <p className={styles.item}>
                  <img src={tools} alt="tools" />
                  Tools
                </p>
              </div>
            </div>
            <div className={styles.info}>
              <div className={styles.tag}>
                <p className={styles.hours}>
                  Working hours
                  <img src={time} alt="Working hours" />
                </p>
                <div className={styles.mid}>
                  <div className={styles.days}>
                    <p className={styles.day}>Monday - Thursday</p>
                    <p className={styles.time}>6:00AM - 10:00PM</p>
                  </div>
                  <div className={styles.days}>
                    <p className={styles.day}>Friday - Saturday</p>
                    <p className={styles.time}>6:00AM - 8:00PM</p>
                  </div>
                </div>
                <div className={styles.days}>
                  <p className={styles.day}>Sunday</p>
                  <p className={styles.time}>6:00AM - 7:00PM</p>
                </div>
              </div>
              <div className={styles.action}>
                <p className={styles.cadence}>Pricing (per month)</p>
                <p className={styles.item}>
                  <img src={price} alt="tools" />
                  $11,500
                </p>
                <Button
                  text="Subscribe"
                  small
                  action={() => null}
                  styles={{
                    padding: "15px 45px",
                    fontSize: "13px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.middle}>
        <h3>Similar Workspaces</h3>
        <div className={styles.otherworkspaces}>
          {workspaceArray.map((workspace1, index) => (
            <WorkspaceCard {...workspace1} key={index} />
          ))}
        </div>
        <img className={styles.dots} src={dots} alt="pagination dots" />
      </section>
      <OtherFooter />
    </main>
  );
}