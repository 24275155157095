import React, { ElementType, FunctionComponent } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AllWorkspaces from './AllWorkspaces';
import WorkspaceDetails from './OneWorkspace';

interface RouteElement {
  path: string;
  exact: boolean;
  Child: ElementType;
}

const routes: RouteElement[] = [
  {
    path: "",
    exact: true,
    Child: AllWorkspaces,
  },
  {
    path: ":id",
    exact: false,
    Child: WorkspaceDetails,
  },
];

const Workspace: FunctionComponent = () => {
  const { path: pathName } = useRouteMatch();

  return (
    <Switch>
      {routes.map(({ path, exact, Child }, index) => (
        <Route
          exact={exact}
          path={`/workspaces/${path}`}
          key={index}
        >
          <Child />
        </Route>
      ))}
    </Switch>
  );
};

export default Workspace;
