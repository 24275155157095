import React, { ReactType, useState, ReactNode } from "react";
import style from "./valuetab.module.scss";

interface TabProps {
  name: string;
  component: ReactType;
  home?: boolean;
}

interface Props {
  tabs: TabProps[];
}

const ValueTab = ({ tabs }: Props) => {
  const [current, setCurrent] = useState<any>(0);
  const Component = tabs[current] && tabs[current].component;
  console.log(tabs[current], current);

  return (
    <div className={`${style.valuetab} ${tabs[current].home ? style.home : ""}`}>
      <div className={style.nav}>
        {tabs.map((tab, i) => (
          <a
            key={i}
            href="javascript:void(0)"
            className={i === current ? style.active : ""}
            onClick={() => setCurrent(i)}
          >
            {tab.name}
          </a>
        ))}
      </div>
      <div className={style.content}>
        <Component />
      </div>
    </div>
  );
};

export default ValueTab;
