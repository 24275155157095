import React from 'react';
import Select from "react-select";
import MultiValueSelect from 'Components/MultiValueInput';
import { useFormikContext } from 'formik';
import FormError from 'Components/FormError';
import { FormProps } from '.';

// TODO: fetch skills from API and populate this for the select dropdown
export const skills = [
  { value: "NodeJS", label: "NodeJS"},
  { value: "Angular", label: "Angular"},
  { value: "Php", label: "Php"},
];

// TODO: fetch skills from API and populate this for the select dropdown
export const countries = [
  { value: "Nigeria", label: "Nigeria"},
];

interface SelectOption {
  value: string;
  label: string;
}

const PageTwo = () => {
  const formContext = useFormikContext<FormProps>();
  const { values, handleChange, setFieldValue, errors, touched } = formContext;

  return (
    <div
        style={{
          margin: '25px 0',
        }}
      >
        <label>
          Select Country?
        </label>
        <Select
          onChange={(value) => setFieldValue('country', (value as SelectOption).value)}
          className="basic-single"
          classNamePrefix="select"
          name="color"
          options={countries}
          styles={{
            input: () => ({
              outline: "none",
            }),
            singleValue: (base) => ({
              borderRadius: "5px",
              fontSize: "14px",
              color: '#0251bc',
              lineHeight: "2.2"
            }),
            placeholder: () => ({
              lineHeight: "2.2",
            }),
            indicatorSeparator: () => ({
              width: 0,
            }),
            valueContainer: (base) => ({
              ...base,
              padding: '0.375rem 1.2rem'
            }),
            control: (base, state) => ({
              ...base,
              backgroundColor: '#fff',
              border: '1px solid #c9d7eb',
              color: '#73859e',
              borderRadius: '5px',
              fontSize: '14px',
              marginTop: '10px',
              outline: 'none',
              '&:focus': {
                color: '#0251bc',
                border: '1px solid #0251bc',
                boxShadow: 'none'
              },
              '&:focus, &:hover': {
                userSelect: 'auto',
                border: '1px solid #c9d7eb',
              },
              '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
                border: '1px solid #c9d7eb',
                boxShadow: '0 0 0px 1000px #fff inset',
                backgroundColor: '#fff',
                background: '#fff',
                fontSize: '18px',
                color: '#73859e',
              }
            }),
          }}
        />
        <FormError error={errors.country} touched={touched.country} />
        <MultiValueSelect
          onChange={(value) => setFieldValue('skills', value)}
          value={values.skills}
          options={skills}
          placeholder="Start typing to see skills..."
        />
        <FormError error={errors.skills} touched={touched.skills} />
      </div>
  );
}


export default PageTwo;