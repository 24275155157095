import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({
  Component,
  ...configs
}: {
  Component: any;
  exact: boolean;
  path: string;
}) => {
  const user = useSelector(({ userReducer }: any) => userReducer.user);
  return (
    <Route
      {...configs}
      render={(props) =>
        user && user.account ? (
          <Component {...props} />
        ) : (
          <Redirect to="/auth/signin" />
        )
      }
    />
  );
};

export default ProtectedRoute;
