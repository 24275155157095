import React from 'react';
import Styles from "./index.module.scss";

const FormError = ({ error, touched }: any) => {
  if(error && touched) {
    return <div className={Styles.error}>{error}</div>
  }
  return null;
};

export default FormError;
